import { createRouter, createWebHistory } from 'vue-router'
import * as coreApi from '../api/core'

// Core
import RegisterView from '../views/core/RegisterView.vue'
import LoginView from '../views/core/LoginView.vue'
import AppsView from '../views/core/AppsView.vue'
import PatientsView from '../views/core/PatientsView.vue'
import AccountSettingsView from '../views/core/settings/AccountSettingsView.vue'
import UserSettingsView from '../views/core/settings/UserSettingsView.vue'
import AcceptInviteView from '../views/core/AcceptInviteView.vue'
import AdminView from '../views/core/AdminView.vue'

// Messaging
import PatientSecureChatView from '../views/messaging/PatientSecureChatView.vue'
import PatientChatsView from '../views/messaging/PatientChatsView.vue'
import UnverifiedChatsView from '../views/messaging/UnverifiedChatsView.vue'
import GroupChatsView from '../views/messaging/GroupChatsView.vue'

// Meetings
import OfficeMeetingsView from '@/views/meetings/OfficeMeetingsView.vue'
import JoinMeetingView from '@/views/meetings/JoinMeetingView.vue'

// Forms
import AccountFormsView from '../views/forms/AccountFormsView.vue'
import ImportFormsView from '../views/forms/ImportFormsView.vue'

//Phone
// import MainPhoneView from '../views/phone/MainPhoneView.vue'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/apps',
    name: 'Apps',
    component: AppsView
  },
  {
    path: '/patients',
    name: 'Patients',
    component: PatientsView
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView
  },
  {
    path: '/account-settings/',
    name: 'Account Settings',
    component: AccountSettingsView
  },
  {
    path: '/user-settings/:id?',
    name: 'User Settings',
    component: UserSettingsView
  },
  {
    path: '/invite/:url',
    name: 'Accept Invite',
    component: AcceptInviteView
  },
  {
    name: 'Patient Chats',
    path: '/patient-chats/:id?',
    component: PatientChatsView
  },
  {
    name: 'Unverified Chats',
    path: '/unverified-chats/:id?',
    component: UnverifiedChatsView
  },
  {
    name: 'Group Chats',
    path: '/group-chats/:id?',
    component: GroupChatsView
  },
  {
    name: 'Patient Chat',
    path: '/chat/:url',
    component: PatientSecureChatView
  },
  {
    name: 'Join Meeting',
    path: '/:name/meeting',
    component: JoinMeetingView
  },
  {
    name: 'Meetings',
    path: '/meetings',
    component: OfficeMeetingsView
  },
  {
    name: 'Import Forms',
    path: '/import-forms/',
    component: ImportFormsView
  },
  {
    name: 'Account Forms',
    path: '/account-forms/:id?',
    component: AccountFormsView
  },
  // {
  //   name: 'Phone',
  //   path: '/phone',
  //   component: MainPhoneView
  // }
]

const router = createRouter({
  routes: routes,
  history: createWebHistory()
})

router.beforeResolve(async (to) => {

  // If there is a one time code, use it
  const otc = to.query.otc;
  if (otc) {
    try {
      await coreApi.useOneTimeCode(otc);
    } catch (err) {
      console.error('Failed to use one time code');
    }
  }

})

export default router;