<template>
  <div class="panel-v2 forms-list-sidebar">
    <div class="panel-v2-header">
      <h2 class="heading">Account Forms</h2>
      <button class="button small primary" @click="$emit('openModal', 'create-form')">Add Form</button>
    </div>
    <div class="panel-v2-body">
      <div class="form-list-items">
        <router-link v-for="form of store.accountForms" :key="form.id" :to="`/account-forms/${form.id}`">
          <div class="form-list-item" :data-active="isFormActive(form)">
            <div class="form-icon">
              <img src="@/assets/icons/form.png" alt="Form Image">
            </div>
            <div class="form-description">
              <span class="form-name">{{ form.name }}</span>
              <span class="form-time">{{ formatDate(form.dateLastUpdated) }}</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '../../store'
import * as formsApi from '../../api/forms'
import * as commonUtils from '../../utils/common'

export default {
  name: 'FormsListSidebar',
  data() {
    return {
      isLoading: false,
      error: '',
      store
    }
  },
  created() {
    this.fetchAccountForms();
  },
  methods: {
    async fetchAccountForms() {
      try {
        this.isLoading = true;
        const forms = await formsApi.listForms();
        store.accountForms = forms;

      } catch (err) {
        this.error = err.message;
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    isFormActive(form) {
      return this.$route.params.id == form.id;
    },
    formatDate(dateStr) {
      return commonUtils.formatDate(dateStr);
    }
  },
  emits: ['openModal']
}
</script>

<style scoped>
.panel-v2 {
  width: 400px;
  flex-grow: 0;
  border-right: 1px solid var(--color-border-dark);
  box-shadow: inset 0px 0px 8px #00000011;
}

.panel-v2-body {
  padding: 0;
}

.panel-v2-header {
  background: #FFF;
}

.form-list-items {
  overflow-y: auto;
  padding: 15px;
}

.form-list-items a {
  text-decoration: none;
  color: unset;
}

.form-list-item {
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  background: transparent;
  cursor: pointer;
  border-radius: 10px;
}

.form-list-item[data-active="true"] {
  background: var(--color-fill-med);
}

.form-icon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  padding: 10px;
  background: #EEE;
}

.form-icon img {
  width: 100%;
  display: block;
}

.form-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-name {
  font-weight: bold;
}

.form-time {
  color: #666;
}
</style>