<template>
  <div id="wrapper">
    <div id="full">
      <NavigationBar :links="[
        { path: '/apps', label: 'My Apps', hasNotif: false },
        { path: '/patients', label: 'Patients', hasNotif: false }
      ]" />
      <div id="app-grid">
        <div class="app" disabled>
          <img src="/icons/app-forms.png" alt="Forms App" class="app-icon">
          <h2 class="app-name">Forms</h2>
        </div>
        <div class="app" @click="openModal('messaging-app')">
          <img src="/icons/app-messaging.png" alt="Messaging App" class="app-icon">
          <h2 class="app-name">Messaging</h2>
        </div>
        <div class="app" disabled>
          <img src="/icons/app-meetings.png" alt="Meetings App" class="app-icon">
          <h2 class="app-name">Meetings</h2>
        </div>
        <!-- <div class="app" @click="openModal('phone-app')">
          <img src="/icons/app-meetings.png" alt="Phone App" class="app-icon">
          <h2 class="app-name">Phone</h2>
        </div> -->
      </div>
    </div>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <FormsAppModal v-if="modal.id == 'forms-app'" :hasProduct="true" @launchApp="launchFormsApp()" />
            <MessagingAppModal v-else-if="modal.id == 'messaging-app'" @launchApp="launchMessagingApp()"
              :hasProduct="true" />
            <SchedulingAppModal v-else-if="modal.id == 'scheduling-app'" />
            <MeetingsAppModal v-else-if="modal.id == 'meetings-app'" @launchApp="launchMeetingsApp()"
              :hasProduct="true" />
            <!-- <PhoneAppModal v-else-if="modal.id == 'phone-app'" @launchApp="launchPhoneApp()"
              :hasProduct="true" /> -->
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as coreApi from '../../api/core'
import { store } from '../../store'
import FormsAppModal from '@/modals/core/apps/FormsAppModal.vue';
import MessagingAppModal from '@/modals/core/apps/MessagingAppModal.vue';
import SchedulingAppModal from '@/modals/core/apps/SchedulingAppModal.vue';
import MeetingsAppModal from '@/modals/core/apps/MeetingsAppModal.vue';
import NavigationBar from '@/components/shared/NavigationBar.vue';

export default {
  name: 'AppsView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      store
    }
  },
  created() {
    coreApi.userCheckAuth()
      .then(() => {
        console.info('Login checker - OK');
      })
      .catch(err => {
        if (err.message == 'Permission denied') {
          this.$router.push('/login?ref=/apps');
        }
      })
  },
  mounted() {
    this.attemptFetchAccountInfo();
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    launchPhoneApp() {
      this.closeModal();

      setTimeout(() => {
        this.$router.push('/phone')
      }, 200);
    },
    launchFormsApp() {
      this.closeModal();

      setTimeout(() => {
        this.$router.push('/forms/forms')
      }, 200);
    },
    launchMessagingApp() {
      this.closeModal();

      setTimeout(() => {
        this.$router.push('/patient-chats')
      }, 200);
    },
    launchMeetingsApp() {
      this.closeModal();

      setTimeout(() => {
        this.$router.push('/meetings');
      }, 200);
    },
    async attemptFetchAccountInfo() {
      try {
        const account = await coreApi.fetchAccountInfo();
        store.account = account;
      } catch (err) {
        console.error('Failed to fetch account info', err);
      }
    }
  },
  components: { NavigationBar, FormsAppModal, MessagingAppModal, SchedulingAppModal, MeetingsAppModal }
}
</script>

<style scoped>
#wrapper {
  background-image: radial-gradient(30% 60%, #ffffff, #e1e6ea);
}

#full {
  background: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23e1e6ea' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

#app-grid {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.app {
  width: 250px;
  height: 250px;
  flex-shrink: 0;
  background: #FFF;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 8px #00000022;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 0.2s;
}

.app[disabled] {
  filter: saturate(0);
}

.app:hover {
  box-shadow: 0px 8px 8px #00000022;
  transform: translate(0px, -4px);
}

.app-icon {
  height: 50%;
  aspect-ratio: 1;
  display: block;
}

@media screen and (max-width: 1000px) {
  #app-grid {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 25px;
  }

  .app {
    width: 100%;
  }
}
</style>