<template>
  <div class="modal-card" v-if="page == 'view'">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Contact Methods</h1>
    </div>
    <div class="modal-card-body">
      <div class="contact-method-list">
        <!-- SMS -->
        <div class="method-list-item" ref="methods" v-for="method of patient.contactMethods" :key="method"
          @click.prevent="$emit('openModal', 'edit-contact-method', { patient, method })">
          <div class="method-icon">
            <img src="@/assets/icons/sms.png" v-if="method.type == 'PHONE'" alt="SMS Icon">
            <img src="@/assets/icons/email.png" v-else-if="method.type == 'EMAIL'" alt="Email Icon">
          </div>
          <div class="method-description">
            <span class="method-name">{{ getMethodName(method) }}</span>
            <span class="method-value">{{ getMethodValue(method) }}</span>
          </div>
          <img src="/icons/trash.png" alt="Delete Method" class="trash-icon"
            @click.stop.prevent="$emit('openModal', 'delete-contact-method', { patient, method })">
        </div>
        <div class="method-list-item add-method" @click.stop.prevent="$emit('openModal', 'add-contact-method', { patient })">
          <div class="method-icon">
            <img src="@/assets/icons/add.png" alt="Add Icon">
          </div>
          <span>Add contact method</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatientContactMethodsModal',
  props: {
    patient: Object
  },
  data() {
    return {
      page: 'view',
      method: null
    }
  },
  methods: {
    getMethodName(method) {
      if (method.description) {
        return method.description;
      }

      switch (method.type) {
        case 'EMAIL':
          return 'Email Address';
        case 'PHONE':
          return 'Phone Number';
        default:
          return 'Unknown';
      }
    },
    getMethodValue(method) {
      switch (method.type) {
        case 'EMAIL':
          return method.emailAddress;
        case 'PHONE':
          return this.formatPhoneNumber(method.phoneNumber);
        default:
          return 'Unknown';
      }
    },
    formatPhoneNumber(phone) {
      let v = phone.replace(/\D/g, '').slice(0, 10);
      if (v.length >= 7) {
        return `(${v.substring(0, 3)}) ${v.substring(3, 6)}-${v.substring(6, 10)}`;
      } else if (v.length >= 4) {
        return `(${v.substring(0, 3)}) ${v.substring(3, 6)}`
      } else if (v.length >= 1) {
        return `(${v.substring(0, 3)}`
      }
    }
  },
  emits: ['openModal']
}
</script>

<style scoped>
.modal-card {
  width: 500px;
}

.contact-method-list {
  max-height: 400px;
  margin-top: 15px;
  overflow-y: auto;
}

.method-list-item {
  padding: 15px;
  display: flex;
  gap: 15px;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.method-list-item:hover {
  background: var(--color-fill-med);
}

.method-icon {
  background: var(--color-fill-med);
  width: 50px;
  height: 50px;
  padding: 15px;
  border-radius: 25px;
  display: block;
}

.method-icon img {
  width: 100%;
  height: 100%;
  display: block;
}

.add-method .method-icon {
  background: none;
  border: 2px dashed var(--color-border-med);
}

.method-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.method-name {
  font-weight: bold;
}

.method-description input {
  width: 100%;
  font-size: 16px;
  background: none;
  border: none;
}

.trash-icon {
  width: 20px;
  height: 20px;
  display: block;
  opacity: 0.2;
  cursor: pointer;
}

.trash-icon:hover {
  opacity: 1;
}
</style>