<template>
  <div class="message-file" :data-is-my-side="isMySide" :data-is-image="isImage()" @click="attemptDownloadFile">
    <span class="file-header">
      <img src="@/assets/icons/download.png" alt="Download Icon" class="download-icon">
      <span>
        <span class="file-name">{{ getFileName() }}</span>
        <span class="file-size">({{ getFileSize() }})</span>
      </span>
    </span>
    <div class="file-preview" v-if="isImage()" :style="{ backgroundImage: `url(${src})` }"></div>
  </div>
</template>

<script>
import * as messagingApi from '../../../../api/messaging.js'
import { isMobile } from '../../../../mobile/MobileUtils.js';

export default {
  name: 'MessageFile',
  props: {
    fileContent: String,
    isMySide: Boolean
  },
  data() {
    return {
      src: ''
    }
  },
  created() {
    if (this.isImage()) {
      this.loadImagePreview();
    }
  },
  methods: {
    isImage() {
      if (!this.fileContent || !this.fileContent.mimetype) return false;
      return this.fileContent.mimetype.startsWith('image/')
    },
    getFileName() {
      if (!this.fileContent) return 'Missing File';
      const name = this.fileContent.name;

      if (name.length < 48) {
        return name;
      }

      return name.substring(0, 24) + '...' + name.substring(name.length - 24);
    },
    getFileSize() {
      if (!this.fileContent) return '0 bytes';
      const bytes = this.fileContent.size;

      if (bytes < 1024) {
        return `${bytes} bytes`
      }

      const kb = Math.floor(bytes / 1024);
      if (kb < 1024) {
        return `${kb} kb`
      }

      const mb = Math.floor(kb / 1024);
      return `${mb} mb`;
    },
    async loadImagePreview() {
      const fc = this.fileContent;
      let resObj;

      const isDevMobile = await isMobile();
      if (isDevMobile) {
        const { mimeType, base64 } = await messagingApi.downloadChatFile(fc.id, true); // base64
        resObj = base64;
        this.src = `data:${mimeType};base64,${resObj}`;
        return;
      }

      resObj = await messagingApi.downloadChatFile(fc.id);
      this.src = resObj;
      this.src = URL.createObjectURL(resObj);
    },
    async attemptDownloadFile() {
      try {
        const blob = await messagingApi.downloadChatFile(this.fileContent.id);
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = this.fileContent.name;
        link.click();
        URL.revokeObjectURL(url);
      } catch (err) {
        console.error(err);
        alert('Failed to download file: ' + err.message);
      }
    }
  }
}
</script>

<style scoped>
.message-file {
  border-radius: 15px;
  line-height: 1.5;
  overflow: hidden;
  cursor: pointer;
}

.message-file[data-is-image="true"] {
  width: 400px;
}

.message-file[data-is-my-side="true"] {
  background: #653ee5;
  color: #FFF;
  flex-direction: row-reverse;
}

.message-file[data-is-my-side="false"] {
  background: #eee;
  color: #000;
}

.file-header {
  padding: 12px;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  line-height: 1.5;
}

.download-icon {
  height: 20px;
  display: block;
}

.message-file[data-is-my-side="false"] .download-icon {
  filter: invert(1);
}

.file-preview {
  width: 100%;
  height: 200px;
  background: #F5F5F5;
  border-radius: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.message-file[data-is-my-side="true"] .file-preview {
  border: 2px solid #653ee5;
  border-bottom-right-radius: 15px;
}

.message-file[data-is-my-side="false"] .file-preview {
  border: 2px solid #EEE;
  border-bottom-left-radius: 15px;
}

.file-name {
  margin-right: 10px;
  text-decoration: underline;
  word-break: break-all;
}

.file-size {
  opacity: 0.5;
  text-wrap: nowrap;
}
</style>