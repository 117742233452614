export async function getJson(url) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'GET'
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}

export async function postJson(url, body) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  // Handle 404 - does not exist
  if (res.status == 404) {
    throw Error('Does Not Exist');
  }

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}

export async function postForm(url, formData) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    body: formData,
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}