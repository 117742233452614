<template>
  <div id="full">
    <NavigationBar :links="[
      { path: '/account-settings', label: 'Account', hasNotif: false },
      { path: '/user-settings', label: 'Users', hasNotif: false },
    ]" />
    <!-- Sidebar -->
    <div class="panel-v2 settings-sidebar">
      <div class="panel-v2-header">
        <h2 class="heading">Account Users</h2>
      </div>
      <div class="panel-v2-body">
        <div class="user-list-items" v-if="!loadingUsers">
          <router-link v-for="user of store.accountUsers" :key="user.id" :to="`/user-settings/${user.id}`">
            <div class="user-list-item" :data-active="isUserActive(user)">
              <img class="user-icon" :src="getUserPicture(user)" :alt="user.name">
              <div class="user-description">
                <span class="user-name">{{ user.name }}</span>
                <div class="user-role"> {{ user.practiceRole }}</div>
              </div>
            </div>
          </router-link>
          <div class="user-list-item" v-for="invite of store.accountInvitations" :key="invite.id" @click="openModal('revoke-invite', { invite })">
            <img class="invitation-icon" src="/icons/patient.png" alt="User Invitation">
            <div class="user-description">
              <span class="user-name">{{ invite.name }}</span>
              <!-- <div class="user-role">{{ invitation.practiceRole }}</div> -->
              <div class="user-role">Pending Invite</div>
            </div>
          </div>
          <div class="user-list-item" @click="openModal('invite-user')">
            <img src="@/assets/icons/add.png" alt="Create User" class="add-new-user">
            <div class="patient-description">
              <span>Add new user</span>
            </div>
          </div>
        </div>
        <div class="loading-status">
          <img class="loading-icon" src="/loading.svg" v-if="loadingUsers">
        </div>
      </div>
    </div>
    <!-- Scroll list -->
    <transition name="modal-appear" mode="out-in">
      <div class="wrapper" v-if="getActiveUser()" :key="getActiveUser().id">
        <UserProfilePanel :user="getActiveUser()" />
      </div>
    </transition>
    <!-- Modal -->
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <InviteUserModal v-if="modal.id == 'invite-user'" @closeModal="closeModal" />
            <RevokeInviteModal v-else-if="modal.id == 'revoke-invite'" :invite="modal.data.invite" @onInviteRevoked="handleInviteRevoked"/>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as coreApi from '../../../api/core'
import * as commonUtils from '../../../utils/common'
import { store } from '../../../store'

import NavigationBar from '@/components/shared/NavigationBar.vue';
import InviteUserModal from '../../../modals/core/settings/InviteUserModal.vue';
import UserProfilePanel from '../../../components/core/settings/UserProfilePanel.vue';
import RevokeInviteModal from '../../../modals/core/settings/RevokeInviteModal.vue';

export default {
  name: 'UserSettingsView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      store,
      loadingUsers: false,
    }
  },
  created() {
    this.attemptFetchUsers();
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    isUserActive(user) {
      const params = this.$route.params;
      return user.id == params.id;
    },
    getActiveUser() {
      const params = this.$route.params;
      if (!params.id) return null;
      if (this.loadingUsers) return null;

      return store.accountUsers.find(user => {
        return user.id == params.id;
      })
    },
    getUserPicture(user) {
      return commonUtils.getUserPicture(user);
    },
    handleInviteRevoked(inviteId) {
      store.accountInvitations = store.accountInvitations.filter(invite => {
        return invite.id != inviteId;
      })

      this.closeModal();
    },
    async attemptFetchUsers() {
      try {
        this.loadingUsers = true;
        const users = await coreApi.listAllUsers();
        const invitations = await coreApi.listInvitations();

        store.accountUsers = users;
        store.accountInvitations = invitations;
        console.log("invidations", invitations);

      } catch (err) {
        console.error(err);
        alert('Failed to fetch users');
      } finally {
        this.loadingUsers = false;
      }
    }
  },
  components: { NavigationBar, InviteUserModal, UserProfilePanel, RevokeInviteModal }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
}

.settings-sidebar {
  flex-grow: 0;
  flex-basis: 400px;
  border-right: 1px solid var(--color-border-med);
}

.wrapper {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.wrapper .panel-v2 {
  flex: unset
}

td {
  padding-bottom: 20px;
}

.info {
  margin-left: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-txt-light);
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
}

table {
  width: 100%;
  table-layout: fixed;
}

select {
  font-size: 18px;
}

.user-list-items {
  overflow-y: auto;
}

.user-list-items a {
  text-decoration: none;
  color: unset;
}

.user-list-item {
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  background: transparent;
  cursor: pointer;
  border-radius: 10px;
}

.user-list-item[data-active="true"] {
  background: var(--color-fill-med);
}

.user-icon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: -1px 0px 1px #00000022;
}

.invitation-icon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #EEE;
  padding: 10px;
}

.user-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-name {
  font-weight: bold;
}

.user-role {
  color: #666;
}

.add-new-user {
  width: 50px;
  height: 50px;
  padding: 15px;
  border: 2px dashed var(--color-border-med);
  border-radius: 25px;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  display: block;
  height: 100%;
}
</style>