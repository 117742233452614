<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <!-- <div class="modal-card-header">
      <h1 class="title">{{importJob.name}}</h1>
    </div> -->
    <div class="modal-card-body">
      <div class="form-pages">
        <img class="form-page" v-for="pageImage of importJob.pageImages" :key="pageImage" :src="pageImage" alt="Page Image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportJobModal",
  props: {
    importJob: Object
  },
  emits: ['openModal', 'closeModal']
};
</script>

<style scoped>
.modal-card {
  max-width: 800px;
  padding: 0;
  overflow: hidden;
}

.form-pages {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  gap: 10px;
  padding: 20px;
  background: var(--color-fill-med);
}

.form-page {
  display: block;
  width: 100%;
  box-shadow: 0px 0px 12px #00000022;
}
</style>