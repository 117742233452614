<template>
  <div class="navigation-bar-popup">
    <router-link class="nav-item" to="/account-settings">Account Settings</router-link>
    <router-link class="nav-item" :to="`/user-settings/${store.userInfo.id}`">User Settings</router-link>
    <a class="nav-item" href=# style="border-top: 1px solid var(--color-border-med);"
      @click="attemptLogout()">Logout</a>
  </div>
</template>

<script>
import { logout } from '@/api'
import { store } from '../../store'

export default {
  name: 'NavigationBarPopup',
  data() {
    return {
      store
    }
  },
  methods: {
    async attemptLogout() {
      try {
        await logout();
        this.$router.push('/login');
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style scoped>
.navigation-bar-popup {
  position: absolute;
  right: 0;
  top: calc(100% + 15px);
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  overflow: hidden;
}

.nav-item {
  background: var(--color-fill-light);
  display: block;
  padding: 15px;
  text-wrap: nowrap;
  text-decoration: none;
  color: var(--color-txt-dark);
}

.nav-item:hover {
  background: var(--color-fill-med);
}
</style>