<template>
  <div id="full" :data-embed-view="isEmbeddedView()" :data-chat-open="chatInfo != null">
    <!-- Navigation bar -->
    <NavigationBar :links="[
      { path: '/patient-chats', label: 'Patients', indicator: store.indicators.unreadPatientChats },
      { path: '/unverified-chats', label: 'Unverified', indicator: store.indicators.unreadUnverifiedChats },
      { path: '/group-chats', label: 'Group Chats', indicator: store.indicators.unreadGroupChats }
    ]" />

    <!-- Sidebar for chat list -->
    <ChatListSidebar :chats="getChatsToShow()" :isLoadingChats="isLoadingChats" :doneLoadingChats="doneLoadingChats"
      @onLoadMoreChats="loadNextChats" emptyText="Patient chats will appear here">
      <h2 class="heading">Recent Chats</h2>
      <button class="button small primary" @click.prevent="openModal('select-patient')">Start Chat</button>
    </ChatListSidebar>

    <!-- Conversation -->
    <transition mode="out-in" name="modal-appear">
      <ChatConversationPanel v-if="chatInfo" ref="panel" :messages="getMessagesToShow()"
        :isLoadingMessages="isLoadingMessages" :doneLoadingMessages="doneLoadingMessages"
        @onLoadMoreMessages="loadNextMessages" :showActions="true" @openModal="openModal">
        <!-- Conversation header -->
        <template v-slot:header>
          <PatientChatConversationHeader :chatInfo="chatInfo" :showBackArrow="!isEmbeddedView()" @onMarkedResolved="markChatResolved" />
        </template>
        <!-- Conversation footer -->
        <template v-slot:footer>
          <ChatConversationFooter ref="panelFooter" :isSendingMessage="isSendingMessage">
            <!-- Templates -->
            <template v-slot:left>
              <button class="button small secondary templates-button" @click="openModal('select-template')">
                <img src="@/assets/icons/template.png" alt="Template Icon">
              </button>
            </template>
            <!-- Send Button -->
            <template v-slot:right>
              <PatientChatSendButton :patient="chatInfo.patient" @onSendMessage="handleSendMessage"
                :isSendingMessage="isSendingMessage" :text="getCurrentText()" :files="getCurrentFiles()" />
            </template>
          </ChatConversationFooter>
        </template>
      </ChatConversationPanel>
    </transition>

    <!-- Modal -->
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <OptInContactMethodModal v-if="modal.id == 'contact-method-opt-in'" :patient="modal.data.patient" :contactMethod="modal.data.contactMethod" @onMethodOptedIn="handleMethodOptedIn" />
            <DeletedChatModal v-else-if="modal.id == 'deleted-chat'" @closeModal="closeModal" />
            
            <PatientSelectModal v-else-if="modal.id == 'select-patient'" @onSelectPatient="handleOpenChat" />
            <!-- Templates -->
            <SelectTemplateModal v-else-if="modal.id == 'select-template'" :chatInfo="chatInfo" @openModal="openModal"
              @selectTemplate="handleSelectTemplate" />
            <CreateTemplateModal v-else-if="modal.id == 'create-template'" @openModal="openModal"
              @closeModal="closeModal" />
            <EditTemplateModal v-else-if="modal.id == 'edit-template'" :template="modal.data.template"
              @openModal="openModal" @closeModal="closeModal" />
            <PopulateTemplateModal v-else-if="modal.id == 'populate-template'" :text="modal.data.text"
              @selectTemplate="handleSelectTemplate" />
            <!-- Actions -->
            <ForwardMessageModal v-else-if="modal.id == 'forward-message'" refChatType="PatientChat"
              :refMessageId="modal.data.refMessageId" />
            <UnsendSecureMessageModal v-else-if="modal.id == 'unsend-message'" :message="modal.data.message"
              @onMessageUnsent="handleMessageUnsent" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import NavigationBar from '../../components/shared/NavigationBar.vue';
import ChatListSidebar from '../../components/messaging/shared/ChatListSidebar.vue';
import ChatConversationPanel from '../../components/messaging/shared/ChatConversationPanel.vue';
import PatientChatConversationHeader from '../../components/messaging/officepatientchat/PatientChatConversationHeader.vue';
import ChatConversationFooter from '../../components/messaging/shared/ChatConversationFooter.vue'
import PatientChatSendButton from '../../components/messaging/officepatientchat/PatientChatSendButton.vue';

import DeletedChatModal from '../../modals/messaging/shared/DeletedChatModal.vue';
import OptInContactMethodModal from '../../modals/shared/contactmethod/OptInContactMethodModal.vue';
import PatientSelectModal from '../../modals/shared/PatientSelectModal.vue'
import SelectTemplateModal from '../../modals/messaging/templates/SelectTemplateModal.vue'
import CreateTemplateModal from '../../modals/messaging/templates/CreateTemplateModal.vue'
import PopulateTemplateModal from '../../modals/messaging/templates/PopulateTemplateModal.vue'
import EditTemplateModal from '../../modals/messaging/templates/EditTemplateModal.vue'
import ForwardMessageModal from '../../modals/messaging/shared/ForwardMessageModal.vue';
import UnsendSecureMessageModal from '../../modals/messaging/UnsendSecureMessageModal.vue';

import { store } from '../../store'
import * as socket from '../../socket'
import * as commonUtils from '../../utils/common'
import * as coreApi from '../../api/core'
import * as messagingApi from '../../api/messaging'

const FAKE_LATENCY = 100;

export default {
  name: 'PatientChatsView',
  data() {
    return {
      store,
      modal: {
        open: false,
        id: '',
        data: {}
      },

      chats: [],
      isLoadingChats: false,
      doneLoadingChats: false,

      chatInfo: null,
      messages: [],
      isLoadingMessages: false,
      doneLoadingMessages: false,

      isSendingMessage: false,
      isResolvingChat: false,
      cancelRequestsBefore: new Date()
    }
  },
  created() {
    Promise.all([
      this.fetchIndicators(),
      this.loadLatestChats(),
      this.fetchAccountInfo()
    ]).then(() => {
      console.info('Loaded data, connecting to socket');
      socket.connectToRoom('account', store.accountInfo.id);
    })
  },
  mounted() {
    socket.addEventListener('chat-event', this.handleChatUpdateEvent);
  },
  unmounted() {
    socket.removeEventListener('chat-event', this.handleChatUpdateEvent);
  },
  watch: {
    $route: {
      handler: function (to) {
        if (this.isEmbeddedView()) {
          this.chatInfo = { patient: { firstName: 'Loading', lastName: 'Patient' }, isResolved: true }
        } else {
          this.chatInfo = null;
        }

        const patientId = to.params.id;
        if (patientId) {
          this.cancelRequestsBefore = new Date(); // prevent old requests from messing up UI
          this.loadChatInfo(patientId);
        }
      },
      immediate: true
    }
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    async fetchAccountInfo() {
      try {
        const accountInfo = await coreApi.fetchAccountInfo();
        store.accountInfo = accountInfo;

        const accountMessagingInfo = await messagingApi.fetchAccountMessagingInfo();
        store.accountMessagingInfo = accountMessagingInfo;
      } catch (err) {
        console.error(err.message);
      }
    },
    async fetchIndicators() {
      try {
        const indicators = await messagingApi.fetchIndicators();
        store.indicators = indicators;
        console.info('Loaded indicators', indicators);
      } catch (err) {
        console.error('Failed to fetch indicators', err);
      }
    },
    async loadLatestChats() {
      try {
        this.isLoadingChats = true;
        const { chats, isLast } = await messagingApi.listLatestPatientChats();
        this.chats = chats;
        this.doneLoadingChats = isLast;
      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.isLoadingChats = false;
      }
    },
    async loadNextChats() {
      if (this.isLoadingChats) {
        return; // Already loaded
      }

      try {
        this.isLoadingChats = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of chats
        const oldestLoadedChat = this.chats[this.chats.length - 1];
        const { chats, isLast } = await messagingApi.listLatestPatientChats(oldestLoadedChat.id);

        // Push new chats to list
        this.doneLoadingChats = isLast;
        this.chats.push(...chats);

      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.isLoadingChats = false;
      }
    },
    async loadChatInfo(patientId) {
      const requestStart = new Date();
      this.messages = [];

      try {
        const chatInfo = await messagingApi.fetchPatientChatInfoForPatient(patientId);
        if (requestStart < this.cancelRequestsBefore) return;
        if (chatInfo) {
          // Got a chat, lets show it
          this.chatInfo = chatInfo;

          const query = this.$route.query;
          if (query && query.highlight) {
            // Load highlighted message (and everything after), scroll message into view
            await this.loadMessagesFrom(query.highlight);

            this.$nextTick(() => {
              const panelRef = this.$refs.panel;
              if (panelRef) {
                panelRef.scrollToMessage(query.highlight);
              }
            })
          } else {
            // Load latest messages, and scroll to bottom
            await this.loadLatestMessages();

            this.$nextTick(() => {
              const panelRef = this.$refs.panel;
              if (panelRef) {
                panelRef.scrollToBottom();
              }
            })
          }
        } else {
          // Got a 404, set up a dummy patient chat
          const patient = await coreApi.fetchPatientInfo(patientId);
          this.chatInfo = { patient, isResolved: true }
        }

      } catch (err) {
        if (err.message == 'Does Not Exist') {
          this.openModal('deleted-chat');
          return;
        }

        console.error('Failed to fetch chat info', err);
        alert('Failed to load chat: ' + err.message);
      }
    },
    async loadMessagesFrom(messageId) {
      if (!this.chatInfo.id) {
        this.doneLoadingMessages = true;
        return;
      }

      this.doneLoadingMessages = false;

      try {
        this.isLoadingMessages = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await messagingApi.patientChatListMessages(chatId, undefined, messageId);
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async loadLatestMessages() {
      const requestStart = new Date();

      if (!this.chatInfo.id) {
        this.doneLoadingMessages = true;
        return;
      }

      this.doneLoadingMessages = false;

      try {
        this.isLoadingMessages = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await messagingApi.patientChatListMessages(chatId);
        if (requestStart < this.cancelRequestsBefore) return;
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async loadNextMessages() {
      const requestStart = new Date();

      if (this.isLoadingMessages) {
        return;
      }

      try {
        this.isLoadingMessages = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of messages
        const oldestLoadedMessage = this.messages[0];
        const { messages, isLast } = await messagingApi.patientChatListMessages(this.chatInfo.id, oldestLoadedMessage.id);
        if (requestStart < this.cancelRequestsBefore) return;

        // Push new chats to list
        this.doneLoadingMessages = isLast;
        for (let message of messages) {
          this.messages.unshift(message);
        }

      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async sendUserMessage(contactMethod, isSecure, text, files) {
      try {
        this.isSendingMessage = true;

        if (!contactMethod.isOptedIn) {
          const patient = this.chatInfo.patient;
          this.openModal('contact-method-opt-in', { patient, contactMethod, isSecure, text, files });
          return;
        }

        // If chat does not exist, create it
        if (!this.chatInfo.id) {
          const chat = await messagingApi.createPatientChat(this.chatInfo.patient.id);
          this.chatInfo = chat;
          this.chats.unshift(chat);
          console.info('Created patient chat');
        }

        // Send files
        let message;
        for (let file of files) {
          message = await messagingApi.patientChatSendUserFile(contactMethod.id, isSecure, file);
          this.messages.push(message);
        }

        // Send message
        if (text) {
          message = await messagingApi.patientChatSendUserText(contactMethod.id, isSecure, text);
          this.messages.push(message);
        }

        // Clear text
        const panelFooterRef = this.$refs.panelFooter;
        if (panelFooterRef) {
          panelFooterRef.clearTextArea();
        }

        // Scroll to bottom
        this.$nextTick(() => {
          const panelRef = this.$refs.panel;
          if (panelRef) {
            panelRef.scrollToBottom();
          }
        })

      } catch (err) {
        console.error('Failed to send message', err);
        alert('Failed to load messages: ' + err.message);
      } finally {
        this.isSendingMessage = false;
      }
    },
    async markChatResolved() {
      try {
        this.isResolvingChat = true;

        // Mark chat as resolved
        const chat = await messagingApi.markPatientChatResolved(this.chatInfo.id, true);
        store.indicators.unreadPatientChats--;
        this.chatInfo = chat;

        // Mark chat as resolved in sidebar
        this.chats = this.chats.map(cht => {
          if (cht.id != chat.id) return cht;
          return chat;
        })

      } catch (err) {
        console.error(err);
        alert('Failed to mark chat as resolved');
      } finally {
        this.isResolvingChat = false;
      }
    },
    async loadUpdatedChat(chatId) {
      if (!chatId) return;

      try {
        // Fetch chat from backend
        const chat = await messagingApi.fetchPatientChatInfo(chatId);

        // Update chat info if this is active chat
        if (this.chatInfo && this.chatInfo.id == chat.id) {
          this.chatInfo = chat;
        }

        // Insert in correct index
        const chats = this.chats.filter(c => c.id != chat.id);
        chats.push(chat);
        chats.sort((a, b) => new Date(b.dateLastUpdated) - new Date(a.dateLastUpdated));
        this.chats = chats;
        console.info('Refreshed patient chat info', chatId);
      } catch (err) {
        console.error('Failed to fetch chat info', err);
      }
    },
    async loadUpdatedChatMessage(messageId) {
      if (!messageId) return;

      try {
        // Fetch message from backend
        const message = await messagingApi.patientChatMessageInfo(messageId);
        let indexToInsertAt = this.messages.findIndex(msg => msg.dateCreated > message.dateCreated);

        // Insert into correct index
        this.messages = this.messages.filter(msg => msg.id != message.id);
        if (indexToInsertAt == -1) {
          this.messages.push(message);
        } else {
          this.messages.splice(indexToInsertAt, 0, message);
        }

        this.$nextTick(() => {
          const panelRef = this.$refs.panel;
          if (!panelRef) return;
          panelRef.scrollToBottom();
        })

        console.info('Refreshed chat message info', messageId);
      } catch (err) {
        console.error('Failed to fetch chat message info', err);
      }
    },
    handleChatUpdateEvent(event) {
      const { chatType, chatId, messageId } = event;

      console.log(event);
      if (chatType == 'PatientChat') {
        // Reload chat info
        this.loadUpdatedChat(chatId);

        if (this.chatInfo && this.chatInfo.id == chatId) {
          // Reload chat message info
          this.loadUpdatedChatMessage(messageId);
        }

      }

      this.fetchIndicators();
    },
    handleSelectTemplate(text) {
      const panelFooterRef = this.$refs.panelFooter;
      if (!panelFooterRef) return;

      // Send message
      panelFooterRef.setText(text);
      this.closeModal();
    },
    handleMethodOptedIn(patient) {
      // Prepare message to send
      const { text, files, contactMethod, isSecure } = this.modal.data;
      this.closeModal();

      // Update patient and send message
      contactMethod.isOptedIn = true;
      this.chatInfo.patient = patient;
      this.sendUserMessage(contactMethod, isSecure, text, files);
    },
    handleOpenChat(patient) {
      this.closeModal();
      this.$router.push(`/patient-chats/${patient.id}`);
    },
    handleSendMessage(contactMethod, isSecure) {
      // Send message
      const text = this.getCurrentText();
      const files = this.getCurrentFiles();
      this.sendUserMessage(contactMethod, isSecure, text, files);
    },
    handleMessageUnsent(messageId) {
      this.closeModal();

      setTimeout(() => {
        this.messages = this.messages.filter(message => {
          return message.id != messageId;
        })
      }, 500)
    },
    getChatsToShow() {
      const result = [];

      for (let chat of this.chats) {
        result.push({
          id: chat.id,
          link: `/patient-chats/${chat.patient.id}`,
          name: commonUtils.getPatientName(chat.patient),
          time: commonUtils.formatDate(chat.dateLastUpdated),
          icons: [{
            src: commonUtils.getPatientPicture(chat.patient),
            alt: 'Patient Profile Picture'
          }],
          isResolved: chat.isResolved
        })
      }

      if (this.chatInfo) {
        const isChatInList = result.some(chat => chat.id == this.chatInfo.id);
        if (!isChatInList) {
          result.unshift({
            id: '',
            link: `/patient-chats/${this.chatInfo.patient.id}`,
            name: commonUtils.getPatientName(this.chatInfo.patient),
            time: 'New Chat',
            icons: [{
              src: commonUtils.getPatientPicture(this.chatInfo.patient),
              alt: 'Patient Profile Picture'
            }],
            isResolved: true
          })
        }
      }

      return result;
    },
    getMessagesToShow() {
      const result = [];

      for (let message of this.messages) {
        
        let contactMethod = 'Portal';
        if (message.contactMethod) {
          const cm = message.contactMethod;
          const type = cm.type;
          if (cm.description) {
            contactMethod = cm.description;
          } else if (type == 'PHONE') {
            contactMethod = cm.phoneNumber;
          } else if (type == 'EMAIL') {
            contactMethod = cm.emailAddress;
          }
        }
        
        result.push({
          id: message.id,
          dateCreated: message.dateCreated,
          isMySide: message.senderType != 'Patient',
          senderId: message.sender.id,
          senderName: message.sender.name,
          senderPicture: commonUtils.getPatientPicture(message.sender),
          contactMethod: contactMethod,
          isSecure: message.isSecure,
          contentType: message.contentType,
          textContent: message.textContent,
          fileContent: message.fileContent
        })
      }

      return result;
    },
    isEmbeddedView() {
      const query = this.$route.query;
      return query && query.embed != null;
    },
    getCurrentText() {
      const panelFooterRef = this.$refs.panelFooter;
      if (!panelFooterRef) return '';
      return panelFooterRef.getText();
    },
    getCurrentFiles() {
      const panelFooterRef = this.$refs.panelFooter;
      if (!panelFooterRef) return [];
      return panelFooterRef.getFiles();
    }
  },
  components: {
    NavigationBar, ChatListSidebar, ChatConversationPanel, ChatConversationFooter,
    PatientChatConversationHeader, PatientChatSendButton, OptInContactMethodModal,
    PatientSelectModal, SelectTemplateModal, CreateTemplateModal, PopulateTemplateModal, EditTemplateModal,
    ForwardMessageModal, UnsendSecureMessageModal, DeletedChatModal
  }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
}

.templates-button {
  height: 44px;
}

.templates-button img {
  height: 18px;
  display: block;
}

@media screen and (max-width: 1000px) {
  #full[data-chat-open="true"] {
    padding: 0px;
  }

  #full[data-chat-open="true"]>nav {
    display: none;
  }
}

#full[data-embed-view="true"] {
  padding: 0px;
}

#full[data-embed-view="true"]>nav {
  display: none;
}
</style>