<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Create Patient Profile</h1>
    </div>
    <div class="modal-card-body">
      <form class="form" @submit.prevent="attemptCreatePatient">
        <div class="form-group">
          <label class="form-label" for="first-name">First Name <span class="required">(required)</span></label>
          <input type="text" id="first-name" class="form-control" autocomplete="off" data-lpignore="true"
            data-form-type="other" v-model="firstName">
        </div>
        <div class="form-group">
          <label class="form-label" for="last-name">Middle Name</label>
          <input type="text" id="middle-name" class="form-control" autocomplete="off" data-lpignore="true"
            data-form-type="other" v-model="middleName">
        </div>
        <div class="form-group">
          <label class="form-label" for="last-name">Last Name <span class="required">(required)</span></label>
          <input type="text" id="last-name" class="form-control" autocomplete="off" data-lpignore="true"
            data-form-type="other" v-model="lastName">
        </div>
        <div class="form-group">
          <label class="form-label" for="date-of-birth">Date of Birth <span class="required">(required)</span></label>
          <input type="text" id="date-of-birth" class="form-control" autocomplete="off" data-lpignore="true"
            data-form-type="other" placeholder="MM/DD/YYYY" v-model="dateOfBirth" @input="formatDateOfBirth">
        </div>
        <input type="submit" value="" style="display: none">
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click="attemptCreatePatient">
        <img v-if="pendingCreate" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Create Patient</span>
      </button>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as coreApi from '../../api/core.js'

export default {
  name: 'PatientCreateModal',
  props: {
    contactMethods: Array
  },
  data() {
    return {
      // Create patient
      pendingCreate: false,
      firstName: '',
      middleName: '',
      lastName: '',
      dateOfBirth: '',
      error: ''
    };
  },
  methods: {
    async attemptCreatePatient() {

      if (this.pendingCreate) return;

      try {
        this.error = '';
        this.pendingCreate = true;

        // Create a patient
        this.patient = await coreApi.createPatient({
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          dateOfBirth: this.dateOfBirth,
          contactMethods: this.contactMethods ? this.contactMethods : []
        });

        // Emit create event, in case we want to handle that
        this.$emit('onCreatePatient', this.patient);

      } catch (err) {
        this.error = err.message;
      } finally {
        this.pendingCreate = false;
      }
    },
    formatDateOfBirth() {
      let v = this.dateOfBirth;
      v = v.replace(/\D/g, '').slice(0, 8);  // Remove non-digits and limit length
      if (v.length >= 5) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
      } else if (v.length >= 3) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2)}`;
      } else {
        this.dateOfBirth = v;
      }
    }
  },
  emits: ['onCreatePatient']
}
</script>

<style scoped>
.modal-card {
  width: 500px;
}
</style>