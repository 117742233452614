import { postJson, postForm } from './common.js'

export async function createForm(name) {
  const resJson = await postJson('/forms/form/create', { name });
  if (!resJson.form) throw Error('Malformed response from backend');
  return resJson.form;
}

export async function listForms() {
  const resJson = await postJson('/forms/form/list');
  if (!resJson.forms) throw Error('Malformed response from backend');
  return resJson.forms;
}

export async function fetchFormInfo(formId) {
  const resJson = await postJson('/forms/form/info', { id: formId });
  if (!resJson.form) throw Error('Malformed response from backend');
  return resJson.form;
}

export async function updateForm(formId, layout) {
  await postJson('/forms/form/update', { id: formId, layout: layout });
}

export async function fetchImportJobs() {
  const resJson = await postJson('/forms/importjob/list');
  if (!resJson.importJobs) throw Error('Malformed response from backend');
  return resJson.importJobs;
}

export async function createImportJobs(files) {
  const formData = new FormData();

  for (let file of files) {
    formData.set(file.name, file);
  }

  const resJson = await postForm('/forms/importjob/create', formData);
  if (!resJson.importJobs) throw Error('Malformed response from backend');
  return resJson.importJobs;
}

export async function startImportJob(jobId) {
  const resJson = await postJson('/forms/importjob/start', { jobId });
  if (!resJson.importJob) throw Error('Malformed response from backend');
  return resJson.importJob;
}


export async function sendSubmission(patientId, formIds) {
  const resJson = await postJson('/forms/submission/send', { patientId, formIds });
  if (!resJson.submission) throw Error('Malformed response from backend');
  return resJson.submission;
}

export async function listSubmissions() {
  const resJson = await postJson('/forms/submission/list');
  if (!resJson.submissions) throw Error('Malformed response from backend');
  return resJson.submissions;
}
