<template>
  <div class="panel">
    <div class="panel-header">
      <h2>Customer Accounts</h2>
    </div>
    <AccountListTable :accounts="accounts" @openAccountInfo="openAccountInfo" @onRemoveAccount="onRemoveAccount" @onEditForms="onEditForms" @onEditMessaging="onEditMessaging" @onEditMeetings="onEditMeetings" />
  </div>
</template>

<script>
import { listAccounts } from '@/api';
import AccountListTable from './AccountListTable.vue';

export default {
  name: "AccountListPanel",
  data() {
    return {
      loading: false,
      accounts: [],
    }
  },
  mounted() {
    this.loadAccounts();
  },
  methods: {
    async loadAccounts() {
      try {
        this.loading = true;
        this.accounts = await listAccounts();
      } catch (err) {
        console.error('Failed to load accounts');
      } finally {
        this.loading = false;
      }
    },
    handleAccountRemoved(accountId) {
      this.accounts = this.accounts.filter(acc => acc.id != accountId);
    },
    handleFormsToggled(accountId, isEnabled) {
      const account = this.accounts.find(acc => acc.id == accountId);
      if (!account) return;
      account.isFormsEnabled = isEnabled;
    },
    handleMessagingToggled(accountId, isEnabled) {
      const account = this.accounts.find(acc => acc.id == accountId);
      if (!account) return;
      account.isMessagingEnabled = isEnabled;
    },
    handleMeetingsToggled(accountId, isEnabled) {
      const account = this.accounts.find(acc => acc.id == accountId);
      if (!account) return;
      account.isMeetingsEnabled = isEnabled;
    },
    onRemoveAccount(account) {
      this.$emit('openModal', 'remove-account', { account: account })
    },
    onEditForms(account) {
      this.$emit('openModal', 'edit-forms', { account: account });
    },
    onEditMessaging(account) {
      this.$emit('openModal', 'edit-messaging', { account: account });
    },
    onEditMeetings(account) {
      this.$emit('openModal', 'edit-meetings', { account: account });
    },
    openAccountInfo(account) {
      this.$emit('openModal', 'account-info', { account: account });
    }
  },
  emits: ["openModal"],
  components: { AccountListTable }
};
</script>

<style scoped>
.panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.table {
  height: 1px;
  flex: 1;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
}

.table-header {
  padding: 15px 25px;
  padding-left: 100px;
  padding-right: 75px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 25px;
  position: relative;
  z-index: 100;
}

.column-header {
  flex: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

#search-bar {
  position: relative;
  width: 500px;
}

#search-bar .form-control {
  padding-left: 44px;
}

#search-bar img {
  position: absolute;
  left: 10px;
  top: calc(50% - 10px);
  height: 20px;
  width: 20px;
  display: block;
  opacity: 0.75;
}

.patient-list-items {
  height: 1px;
  flex: 1;
  overflow-y: overlay;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  display: block;
  height: 100%;
}

.loading-done {
  color: #CCC;
}
</style>