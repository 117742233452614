<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Confirm Patient Consent</h1>
      <p class="subtitle">Please confirm that you have obtained the patient's consent, either verbally or in writing, to
        receive messages to <u>{{ getMethodName() }}</u>.</p>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptOptInMethod">
        <img v-if="loading" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Confirm & Send</span>
      </button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>

import * as coreApi from '../../../api/core'

export default {
  name: "OptInContactMethodModal",
  props: {
    patient: Object,
    contactMethod: Object
  },
  data() {
    return {
      loading: false,
      error: ''
    }
  },
  methods: {
    async attemptOptInMethod() {
      if (this.loading) {
        return;
      }

      try {
        this.error = '';
        this.loading = true;

        const patient = await coreApi.optInContactMethod({ patientId: this.patient.id, methodId: this.contactMethod.id, isOptedIn: true });
        this.$emit('onMethodOptedIn', patient);

      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    formatPhoneNumber(phone) {
      return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
    },
    getMethodName() {
      const { type, description, phoneNumber, emailAddress } = this.contactMethod;

      if (type == 'PHONE') {
        const formattedNumber = this.formatPhoneNumber(phoneNumber);
        if (description) {
          return `${formattedNumber} (${description})`
        } else {
          return formattedNumber;
        }
      }

      if (type == 'EMAIL') {
        if (description) {
          return `${emailAddress} (${description})`
        } else {
          return emailAddress;
        }
      }

      return 'Unknown Method'
    }
  },
  emits: ['onMethodOptedIn']
};
</script>

<style scoped>
.modal-card {
  max-width: 525px;
}

.modal-card-footer {
  margin-top: 0;
}

.consent-statement {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 45px;
}
</style>