<template>
  <div class="panel-v2">
    <div class="panel-v2-header">
      <h2 class="heading">Messaging</h2>
      <button class="button small primary" @click="handleButtonClick">
        <img v-if="pending" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else-if="!isEditing">Edit</span>
        <span v-else>Save</span>
      </button>
    </div>
    <div class="panel-v2-body">
      <div class="setting-line-item">
        <label class="setting-label">Allow Insecure Messages</label>
        <div class="view-value" v-if="!isEditing">
          <span v-if="allowInsecureMessages">Enabled</span>
          <span v-else>Disabled</span>
        </div>
        <div class="edit-value" v-else>
          <select v-model="allowInsecureMessages">
            <option :value="true">Enabled</option>
            <option :value="false">Disabled</option>
          </select>
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Hide Resolved Chats</label>
        <div class="view-value" v-if="!isEditing">
          <span v-if="store.accountMessagingInfo.hideResolvedChats">Enabled</span>
          <span v-else>Disabled</span>
        </div>
        <div class="edit-value" v-else>
          <select :value="this.hideResolvedChats" @change="handleMessagingResolveValue(!this.hideResolvedChats)">
            <option :value="true">Enabled</option>
            <option :value="false">Disabled</option>
          </select>
        </div>
      </div>

      <div class="setting-line-item">
        <label class="setting-label">Allow Out of Hours Auto Reply</label>
        <div class="view-value" v-if="!isEditing">
          <span v-if="store.accountMessagingInfo.outOfHoursAutoReplyEnabled">Enabled</span>
          <span v-else>Disabled</span>
        </div>
        <div class="edit-value" v-else>
          <select :value="this.outOfHoursAutoReplyEnabled" @change="handleOutOfHoursAutoReplyEnable(!this.outOfHoursAutoReplyEnabled)">
            <option :value="true">Enabled</option>
            <option :value="false">Disabled</option>
          </select>
        </div>
      </div>

      <div class="setting-line-item">
        <label class="setting-label">Out of Hours Auto Reply Message</label>
        <div class="view-value" v-if="!isEditing">{{ store.accountMessagingInfo.outOfHoursAutoReplyMessage }}</div>
        <div class="edit-value" v-else>
          <i v-if="this.outOfHoursAutoReplyMessage == ''">Disabled</i>
          <textarea type="text" autocomplete="off" data-lpignore="true" data-form-type="other" v-model="this.outOfHoursAutoReplyMessage"></textarea>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import * as messagingApi from '../../../api/messaging'
import { store } from '../../../store'

const FAKE_LATENCY = 500;

export default {
  name: 'PublicProfilePanel',
  data() {
    return {
      store,
      isEditing: false,
      pending: false,
      error: '',
      allowInsecureMessages: store.accountMessagingInfo.allowInsecureMessages,
      hideResolvedChats: store.accountMessagingInfo.hideResolvedChats,
      outOfHoursAutoReplyEnabled: store.accountMessagingInfo.outOfHoursAutoReplyEnabled,
      outOfHoursAutoReplyMessage: store.accountMessagingInfo.outOfHoursAutoReplyMessage,
    }
  },
  methods: {
    async handleButtonClick() {
      if (this.pending) return;

      if (this.isEditing) {

        try {
          this.error = '';
          this.pending = true;

          await new Promise((resolve) => {
            setTimeout(resolve, FAKE_LATENCY)
          })

          const accountMessagingSettings = await messagingApi.setAccountMessagingInfo({
            outOfHoursAutoReplyEnabled: this.outOfHoursAutoReplyEnabled,
            outOfHoursAutoReplyMessage: this.outOfHoursAutoReplyMessage,
            hideResolvedChats: this.hideResolvedChats,
            allowInsecureMessages: this.allowInsecureMessages
          })

          store.accountMessagingInfo = accountMessagingSettings;

        } catch (err) {
          this.error = err.message;
          return;
        } finally {
          this.pending = false;
        }
      }

      this.isEditing = !this.isEditing;
    },
    handleOutOfHoursAutoReplyEnable(value) {
      if (value === true) {
        this.outOfHoursAutoReplyEnabled = true;
      } else {
        this.outOfHoursAutoReplyEnabled = false;
      }
      console.log(this.outOfHoursAutoReplyEnabled)
    },
    handleMessagingResolveValue(value) {
      if (value === true) {
        this.hideResolvedChats = true;
      } else {
        this.hideResolvedChats = false;
      }
      console.log(this.hideResolvedChats);
    },
    handleChangeValue(method, event) {
      const value = event.target.value;

      this.allowedMessageMethods = this.allowedMessageMethods.filter(meth => {
        return meth != method;
      })

      if (value == "true") {
        this.allowedMessageMethods.push(method);
      }

      this.allowedMessageMethods.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      })

      console.log(this.allowedMessageMethods);
    },
    isMethodEnabled(method) {
      return this.allowedMessageMethods.includes(method)
    },
    getStatusForMethod(method) {
      if (this.isMethodEnabled(method)) {
        return 'Enabled';
      }

      return '-';
    },
  }
}
</script>

<style scoped>
.panel-v2-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.setting-line-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.setting-line-item>* {
  flex: 1;
}

label {
  color: var(--color-txt-light);
}

input,
textarea,
select {
  border: none;
  border: 1px solid var(--color-border-med);
  font-size: 16px;
  padding: 10px;
}

textarea {
  width: 100%;
  resize: vertical;
}

.loading {
  height: 19px;
}
</style>