<template>
  <div id="full" :data-chat-open="chatInfo != null">
    <!-- Navigation bar -->
    <NavigationBar :links="[
      { path: '/patient-chats', label: 'Patients', indicator: store.indicators.unreadPatientChats },
      { path: '/unverified-chats', label: 'Unverified', indicator: store.indicators.unreadUnverifiedChats },
      { path: '/group-chats', label: 'Group Chats', indicator: store.indicators.unreadGroupChats }
    ]" />

    <!-- Sidebar for chat list -->
    <ChatListSidebar :chats="getChatsToShow()" :isLoadingChats="isLoadingChats" :doneLoadingChats="doneLoadingChats"
      @onLoadMoreChats="loadNextChats" emptyText="Unrecognized messages will arrive here.">
      <h2 class="heading">Recent Chats</h2>
    </ChatListSidebar>

    <!-- Conversation -->
    <transition mode="out-in" name="modal-appear">
      <ChatConversationPanel v-if="chatInfo" ref="panel" :messages="getMessagesToShow()"
        :isLoadingMessages="isLoadingMessages" :doneLoadingMessages="doneLoadingMessages" :showActions="true"
        @openModal="openModal" @onLoadMoreMessages="loadNextMessages">
        <template v-slot:header>
          <UnverifiedChatConversationHeader :chatInfo="chatInfo" @onMarkedResolved="markChatResolved"
            @onConvertChat="handleConvertChat" />
        </template>
        <template v-slot:footer>
          <ChatConversationFooter ref="panelFooter">
            <!-- Templates -->
            <template v-slot:left>
              <button class="button small secondary templates-button" @click="openModal('select-template')">
                <img src="@/assets/icons/template.png" alt="Template Icon">
              </button>
            </template>
            <!-- Send Button -->
            <template v-slot:right>
              <button class="button small primary send-button" @click.prevent="handleSendMessage"
                :disabled="isSendButtonDisabled()" v-tooltip="getDisabledButtonMessage()">
                <img v-if="isSendingMessage" src="/loading-white.svg" alt="Loading Icon" class="loading">
                <span v-else>Send</span>
              </button>
            </template>
          </ChatConversationFooter>
        </template>
      </ChatConversationPanel>
    </transition>

    <!-- Modal -->
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <!-- Deleted Chat -->
            <DeletedChatModal v-if="modal.id == 'deleted-chat'" @closeModal="closeModal" />
            <!-- Templates -->
            <SelectTemplateModal v-else-if="modal.id == 'select-template'" :chatInfo="chatInfo" @openModal="openModal"
              @selectTemplate="handleSelectTemplate" />
            <CreateTemplateModal v-else-if="modal.id == 'create-template'" @openModal="openModal"
              @closeModal="closeModal" />
            <EditTemplateModal v-else-if="modal.id == 'edit-template'" :template="modal.data.template"
              @openModal="openModal" @closeModal="closeModal" />
            <PopulateTemplateModal v-else-if="modal.id == 'populate-template'" :text="modal.data.text"
              @selectTemplate="handleSelectTemplate" />
            <PatientCreateModal v-else-if="modal.id == 'create-patient'" :contactMethods="modal.data.contactMethods"
              @onCreatePatient="handlePatientCreated" />
            <!-- Actions -->
            <ForwardMessageModal v-else-if="modal.id == 'forward-message'" refChatType="UnverifiedChat"
              :refMessageId="modal.data.refMessageId" />

          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import NavigationBar from '../../components/shared/NavigationBar.vue';
import ChatListSidebar from '../../components/messaging/shared/ChatListSidebar.vue';
import ChatConversationPanel from '../../components/messaging/shared/ChatConversationPanel.vue';
import ChatConversationFooter from '../../components/messaging/shared/ChatConversationFooter.vue'
import UnverifiedChatConversationHeader from '../../components/messaging/officeunverifiedchat/UnverifiedChatConversationHeader.vue';

import DeletedChatModal from '../../modals/messaging/shared/DeletedChatModal.vue'
import SelectTemplateModal from '../../modals/messaging/templates/SelectTemplateModal.vue'
import CreateTemplateModal from '../../modals/messaging/templates/CreateTemplateModal.vue'
import PopulateTemplateModal from '../../modals/messaging/templates/PopulateTemplateModal.vue'
import EditTemplateModal from '../../modals/messaging/templates/EditTemplateModal.vue'
import ForwardMessageModal from '../../modals/messaging/shared/ForwardMessageModal.vue';
import PatientCreateModal from '../../modals/shared/PatientCreateModal.vue'

import * as commonUtils from '../../utils/common'
import * as coreApi from '../../api/core'
import * as messagingApi from '../../api/messaging'
import * as socket from '../../socket'
import { store } from '../../store'

const FAKE_LATENCY = 100;
const ALLOWED_INSECURE_MIMETYPES = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png']

export default {
  name: 'UnverifiedChatsView',
  data() {
    return {
      store,
      modal: {
        open: false,
        id: '',
        data: {}
      },

      chats: [],
      isLoadingChats: false,
      doneLoadingChats: false,

      chatInfo: null,
      messages: [],
      isLoadingMessages: false,
      doneLoadingMessages: false,

      isSendingMessage: false,
      isResolvingChat: false,
      cancelRequestsBefore: new Date()
    }
  },
  created() {
    Promise.all([
      this.fetchAccountInfo(),
      this.fetchIndicators(),
      this.loadLatestChats()
    ]).then(() => {
      console.info('Loaded data, connecting to socket');
      socket.connectToRoom('account', store.accountInfo.id);
    })
  },
  mounted() {
    socket.addEventListener('chat-event', this.handleChatUpdateEvent);
  },
  unmounted() {
    socket.removeEventListener('chat-event', this.handleChatUpdateEvent);
  },
  watch: {
    $route: {
      handler: function (to) {
        const chatId = to.params.id;
        this.chatInfo = null;
        if (chatId) {
          this.cancelRequestsBefore = new Date();
          this.loadChatInfo(chatId);
        }
      },
      immediate: true
    }
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleConvertChat() {

      const contactMethods = [];

      if (this.chatInfo.mobilePhoneNumber) {
        contactMethods.push({
          methodType: 'PHONE',
          phoneNumber: this.chatInfo.mobilePhoneNumber,
          description: 'Phone Number'
        })
      }

      if (this.chatInfo.emailAddress) {
        contactMethods.push({
          methodType: 'EMAIL',
          emailAddress: this.chatInfo.emailAddress,
          description: 'Email Address'
        })
      }

      this.openModal('create-patient', { contactMethods });
    },
    handlePatientCreated(patient) {
      this.$router.push(`/patient-chats/${patient.id}`)
    },
    async fetchAccountInfo() {
      try {
        const accountInfo = await coreApi.fetchAccountInfo();
        store.accountInfo = accountInfo;

        const accountMessagingSettings = await messagingApi.fetchAccountMessagingInfo();
        store.accountMessagingInfo = accountMessagingSettings;

      } catch (err) {
        console.error(err.message);
      }
    },
    async fetchIndicators() {
      try {
        const indicators = await messagingApi.fetchIndicators();
        store.indicators = indicators;
        console.info('Loaded indicators', indicators);
      } catch (err) {
        console.error('Failed to fetch indicators', err);
      }
    },
    async loadLatestChats() {
      try {
        this.isLoadingChats = true;
        const { chats, isLast } = await messagingApi.listUnverifiedChats();
        this.chats = chats;
        this.doneLoadingChats = isLast;
      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.isLoadingChats = false;
      }
    },
    async loadNextChats() {
      if (this.isLoadingChats) {
        return; // Already loaded
      }

      try {
        this.isLoadingChats = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of chats
        const oldestLoadedChat = this.chats[this.chats.length - 1];
        const { chats, isLast } = await messagingApi.listUnverifiedChats(oldestLoadedChat.id);

        // Push new chats to list
        this.doneLoadingChats = isLast;
        this.chats.push(...chats);

      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.isLoadingChats = false;
      }
    },
    async loadChatInfo(chatId) {
      const requestStart = new Date();

      try {
        const chatInfo = await messagingApi.fetchUnverifiedChatInfo(chatId);
        if (requestStart < this.cancelRequestsBefore) return;
        this.chatInfo = chatInfo;

        const query = this.$route.query;
        if (query && query.highlight) {
          // Load highlighted message (and everything after), scroll message into view
          await this.loadMessagesFrom(query.highlight);

          this.$nextTick(() => {
            const panelRef = this.$refs.panel;
            if (panelRef) {
              panelRef.scrollToMessage(query.highlight);
            }
          })
        } else {
          // Load latest messages, and scroll to bottom
          await this.loadLatestMessages();

          this.$nextTick(() => {
            const panelRef = this.$refs.panel;
            if (panelRef) {
              panelRef.scrollToBottom();
            }
          })
        }

      } catch (err) {
        if (err.message == 'Does Not Exist') {
          this.openModal('deleted-chat')
          return;
        }

        console.error('Failed to fetch chat info', err);
        alert('Failed to load chat: ' + err.message);
      }

    },
    async loadMessagesFrom(messageId) {
      const requestStart = new Date();

      if (!this.chatInfo.id) {
        this.doneLoadingMessages = true;
        return;
      }

      this.doneLoadingMessages = false;

      try {
        this.isLoadingMessages = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await messagingApi.unverifiedChatListMessages(chatId, undefined, messageId);
        if (requestStart < this.cancelRequestsBefore) return;
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async loadLatestMessages() {
      const requestStart = new Date();

      if (!this.chatInfo.id) {
        this.doneLoadingMessages = true;
        return;
      }

      this.messages = [];
      this.doneLoadingMessages = false;

      try {
        this.isLoadingMessages = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await messagingApi.unverifiedChatListMessages(chatId);
        if (requestStart < this.cancelRequestsBefore) return;
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async loadNextMessages() {
      const requestStart = new Date();

      if (this.isLoadingMessages) {
        return;
      }

      try {
        this.isLoadingMessages = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of messages
        const oldestLoadedMessage = this.messages[0];
        const { messages, isLast } = await messagingApi.unverifiedChatListMessages(this.chatInfo.id, oldestLoadedMessage.id);
        if (requestStart < this.cancelRequestsBefore) return;

        // Push new chats to list
        this.doneLoadingMessages = isLast;
        for (let message of messages) {
          this.messages.unshift(message);
        }

      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async sendUserMessage(text, files) {
      try {
        this.isSendingMessage = true;

        // Send files
        let message;
        for (let file of files) {
          message = await messagingApi.unverifiedChatSendUserFile(this.chatInfo.id, file);
          this.messages.push(message);
        }

        // Send message
        if (text) {
          message = await messagingApi.unverifiedChatSendUserText(this.chatInfo.id, text);
          this.messages.push(message);
        }

        // Clear text
        const panelFooterRef = this.$refs.panelFooter;
        if (panelFooterRef) {
          panelFooterRef.clearTextArea();
        }

        // Scroll to bottom
        this.$nextTick(() => {
          const panelRef = this.$refs.panel;
          if (panelRef) {
            panelRef.scrollToBottom();
          }
        })

      } catch (err) {
        console.error('Failed to send message', err);
        alert('Failed to load messages: ' + err.message);
      } finally {
        this.isSendingMessage = false;
      }
    },
    async markChatResolved() {
      try {
        this.isResolvingChat = true;

        // Mark chat as resolved
        const chat = await messagingApi.markUnverifiedChatResolved(this.chatInfo.id, true);
        store.indicators.unreadUnverifiedChats--;
        this.chatInfo = chat;

        // Mark chat as resolved in sidebar
        this.chats = this.chats.map(cht => {
          if (cht.id != chat.id) return cht;
          return chat;
        })

      } catch (err) {
        console.error(err);
        alert('Failed to mark chat as resolved');
      } finally {
        this.isResolvingChat = false;
      }
    },
    async loadUpdatedChat(chatId) {
      if (!chatId) return;

      try {
        // Fetch chat from backend
        const chat = await messagingApi.fetchUnverifiedChatInfo(chatId);

        // Update chat info if this is active chat
        if (this.chatInfo && this.chatInfo.id == chat.id) {
          this.chatInfo = chat;
        }

        // Insert in correct index
        const chats = this.chats.filter(c => c.id != chat.id);
        chats.push(chat);
        chats.sort((a, b) => new Date(b.dateLastUpdated) - new Date(a.dateLastUpdated));
        this.chats = chats;
        console.info('Refreshed unverified chat info', chatId);
      } catch (err) {
        console.error('Failed to fetch chat info', err);
      }
    },
    async loadUpdatedChatMessage(messageId) {
      if (!messageId) return;

      try {
        // Fetch message from backend
        const message = await messagingApi.unverifiedChatMessageInfo(messageId);
        let indexToInsertAt = this.messages.findIndex(msg => msg.dateCreated > message.dateCreated);

        // Insert into correct index
        this.messages = this.messages.filter(msg => msg.id != message.id);
        if (indexToInsertAt == -1) {
          this.messages.push(message);
        } else {
          this.messages.splice(indexToInsertAt, 0, message);
        }

        this.$nextTick(() => {
          const panelRef = this.$refs.panel;
          if (!panelRef) return;
          panelRef.scrollToBottom();
        })
        console.info('Refreshed chat message info', messageId);
      } catch (err) {
        console.error('Failed to fetch chat message info', err);
      }
    },
    handleChatUpdateEvent(event) {
      const { chatType, chatId, messageId } = event;

      console.log(event);
      if (chatType == 'UnverifiedChat') {
        // Reload chat info
        this.loadUpdatedChat(chatId);

        if (this.chatInfo && this.chatInfo.id == chatId) {
          // Reload chat message info
          this.loadUpdatedChatMessage(messageId);
        }
      }

      this.fetchIndicators();
    },
    handleSelectTemplate(text) {
      const panelFooterRef = this.$refs.panelFooter;
      if (!panelFooterRef) return;

      // Send message
      panelFooterRef.setText(text);
      this.closeModal();
    },
    handleSendMessage() {
      // Send message
      const text = this.getCurrentText();
      const files = this.getCurrentFiles();
      this.sendUserMessage(text, files);
    },
    getChatsToShow() {
      const result = [];

      for (let chat of this.chats) {
        let contactName = 'Unknown Contact';
        if (chat.mobilePhoneNumber) {
          contactName = commonUtils.formatPhone(chat.mobilePhoneNumber);
        }
        if (chat.emailAddress) {
          contactName = chat.emailAddress;
        }

        result.push({
          id: chat.id,
          link: `/unverified-chats/${chat.id}`,
          name: contactName,
          time: commonUtils.formatDate(chat.dateLastUpdated),
          icons: [{
            src: '/icons/patient.png',
            alt: 'Patient Profile Picture'
          }],
          isResolved: chat.isResolved
        })
      }

      if (this.chatInfo) {
        const isChatInList = result.some(chat => chat.id == this.chatInfo.id);
        if (!isChatInList) {

          let contactName = 'Unknown Contact';
          if (this.chatInfo.mobilePhoneNumber) {
            contactName = commonUtils.formatPhone(this.chatInfo.mobilePhoneNumber);
          }
          if (this.chatInfo.emailAddress) {
            contactName = this.chatInfo.emailAddress;
          }

          result.unshift({
            id: this.chatInfo.id,
            link: `/unverified-chats/${this.chatInfo.id}`,
            name: contactName,
            time: commonUtils.formatDate(this.chatInfo.dateLastUpdated),
            icons: [{
              src: '/icons/patient.png',
              alt: 'Patient Profile Picture'
            }],
            isResolved: this.chatInfo.isResolved
          })
        }
      }

      return result;
    },
    getMessagesToShow() {
      const result = [];

      let contactMethod = 'Unknown';
      if (this.chatInfo.mobilePhoneNumber) {
        contactMethod = 'SMS'
      }

      for (let message of this.messages) {

        result.push({
          id: message.id,
          dateCreated: message.dateCreated,
          isMySide: message.sender.id != null,
          senderId: message.sender.id,
          senderName: message.sender.name,
          senderPicture: commonUtils.getPatientPicture(message.sender),
          contactMethod: contactMethod,
          isSecure: false,
          contentType: message.contentType,
          textContent: message.textContent,
          fileContent: message.fileContent
        })
      }

      return result;
    },
    getCurrentText() {
      const panelFooterRef = this.$refs.panelFooter;
      if (!panelFooterRef) return '';
      return panelFooterRef.getText();
    },
    getCurrentFiles() {
      const panelFooterRef = this.$refs.panelFooter;
      if (!panelFooterRef) return [];
      return panelFooterRef.getFiles();
    },
    isSendButtonDisabled() {
      // Check that account has messaging enabled
      const accountMessagingInfo = this.store.accountMessagingInfo;
      if (!accountMessagingInfo.allowInsecureMessages) {
        return true;
      }

      // Check we have capability to respond with email
      const accountInfo = this.store.accountInfo;
      if (this.chatInfo.emailAddress && !accountInfo.publicEmailAddress) {
        return true;
      }

      // Check we have capability to respond with phone
      if (this.chatInfo.mobilePhoneNumber && !accountInfo.publicPhoneNumber) {
        return true;
      }

      // Disable sending non-image buttons
      if (this.chatInfo.mobilePhoneNumber) {
        for (let file of this.getCurrentFiles()) {
          if (!ALLOWED_INSECURE_MIMETYPES.includes(file.type)) {
            return true;
          }
        }
      }

      return false;
    },
    getDisabledButtonMessage() {
      // Check that account has messaging enabled
      const accountMessagingInfo = this.store.accountMessagingInfo;
      if (!accountMessagingInfo.allowInsecureMessages) {
        return 'Your account has insecure messaging disabled.';
      }

      // Check we have capability to respond with email
      const accountInfo = this.store.accountInfo;
      if (this.chatInfo.emailAddress && !accountInfo.publicEmailAddress) {
        return 'Your account does not have an email connected.';
      }

      // Check we have capability to respond with phone
      if (this.chatInfo.mobilePhoneNumber && !accountInfo.publicPhoneNumber) {
        return 'Your account does not have a phone number connected.';
      }

      // Disable sending non-image buttons
      if (this.chatInfo.mobilePhoneNumber) {
        for (let file of this.getCurrentFiles()) {
          if (!ALLOWED_INSECURE_MIMETYPES.includes(file.type)) {
            return 'Only .png and .jpeg files can be sent over insecure SMS';
          }
        }
      }

      return null;
    }
  },
  components: {
    NavigationBar, ChatListSidebar,
    ChatConversationPanel, ChatConversationFooter, UnverifiedChatConversationHeader,
    DeletedChatModal, SelectTemplateModal, CreateTemplateModal, PopulateTemplateModal, EditTemplateModal,
    ForwardMessageModal, PatientCreateModal
  }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
}

.send-button {
  height: 44px;
}

.templates-button {
  height: 44px;
}

.templates-button img {
  height: 18px;
  display: block;
}

@media screen and (max-width: 1000px) {
  #full[data-chat-open="true"] {
    padding: 0px;
  }

  #full[data-chat-open="true"]>nav {
    display: none;
  }
}
</style>