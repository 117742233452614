<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Edit Contact Method</h1>
    </div>
    <div class="modal-card-body">
      <form class="form" action="" @submit.prevent="attemptAddContactMethod">
        <div class="form-group">
          <label for="" class="form-label">Method Type <span class="required">(required)</span></label>
          <select class="form-control" :value="method.type" disabled>
            <option value="PHONE">Phone</option>
            <option value="EMAIL">Email</option>
          </select>
        </div>
        <div class="form-group" v-if="method.type == 'EMAIL'">
          <label for="" class="form-label">Email Address <span class="required">(required)</span></label>
          <input type="text" class="form-control" placeholder="example@email.com" :value="method.emailAddress" disabled>
        </div>
        <div class="form-group" v-if="method.type == 'PHONE'">
          <label for="" class="form-label">Phone Number <span class="required">(required)</span></label>
          <input type="text" class="form-control" placeholder="(123) 123-1234" :value="phoneNumber" disabled
            @input="formatDateOfBirth">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Description</label>
          <input type="text" class="form-control" placeholder="Mobile Phone, Parent's Phone, Etc."
            v-model="description">
        </div>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptEditContactMethod">
        <img v-if="pendingEdit" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Save Changes</span>
      </button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import * as coreApi from '../../../api/core'

export default {
  name: "EditContactMethodModal",
  props: {
    patient: Object,
    method: Object
  },
  data() {
    return {
      pendingEdit: false,
      error: '',
      description: this.method.description,
    }
  },
  methods: {
    async attemptEditContactMethod() {
      if (this.pandingEdit) return;

      try {
        this.pendingEdit = true;
        const patient = await coreApi.editContactMethod({patientId: this.patient.id, methodId: this.method.id, description: this.description});
        this.$emit('onMethodEdited', patient);
      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.pendingEdit = false;
      }
    }
  },
  emits: ['onMethodEdited']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}
</style>