<template>
  <div class="panel-v2">
    <div class="panel-v2-header">
      <h2 class="heading">Public Profile</h2>
      <button class="button small primary" @click="handleButtonClick">
        <img v-if="pending" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else-if="!isEditing">Edit</span>
        <span v-else>Save</span>
      </button>
    </div>
    <div class="panel-v2-body">
      <div class="setting-line-item">
        <label class="setting-label">Office Name</label>
        <div class="view-value" v-if="!isEditing">{{ store.accountInfo.name }}</div>
        <div class="edit-value" v-else>
          <input type="text" autocomplete="off" data-lpignore="true" data-form-type="other" v-model="accountName">
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Connected SMS Number</label>
        <div class="view-value" v-if="!isEditing">
          <span v-if="store.accountInfo.publicPhoneNumber">{{ getFormattedPhone() }}</span>
          <i v-else>Not connected</i>
        </div>
        <div class="edit-value" v-else>
          <input type="text" autocomplete="off" data-lpignore="true" data-form-type="other" v-model="publicPhoneNumber">
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Connected Email Address</label>
        <div class="view-value" v-if="!isEditing">
          <span v-if="store.accountInfo.publicEmailAddress">{{ store.accountInfo.publicEmailAddress }}</span>
          <i v-else>Not connected</i>
        </div>
        <div class="edit-value" v-else>
          <input type="text" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="publicEmailAddress">
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Hours of Operation</label>
        <div class="view-value" v-if="!isEditing">
          <span>{{operationLocalStart}} → {{operationLocalEnd}}</span>
        </div>
        <div class="edit-value" v-else>
          <input type="text" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="operationLocalStart" placeholder="00:00">
          <input type="text" autocomplete="off" data-lpignore="true" data-form-type="other" v-model="operationLocalEnd"
            placeholder="24:00">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as coreApi from '../../../api/core'
import { store } from '../../../store'

const FAKE_LATENCY = 500;

export default {
  name: 'PublicProfilePanel',
  data() {
    return {
      store,
      isEditing: false,
      pending: false,
      error: '',

      // Editable
      accountName: store.accountInfo.name,
      publicPhoneNumber: store.accountInfo.publicPhoneNumber,
      publicEmailAddress: store.accountInfo.publicEmailAddress,
      operationLocalStart: this.convertAbsoluteMinutesToLocalTime(store.accountInfo.operationMinStartUTC),
      operationLocalEnd: this.convertAbsoluteMinutesToLocalTime(store.accountInfo.operationMinEndUTC)
    }
  },
  created() {
    console.log('before', 'this.publicEmailAddress', this.publicEmailAddress, 'this.publicPhoneNumber', this.publicPhoneNumber);
  },
  methods: {
    async handleButtonClick() {
      if (this.pending) return;

      if (this.isEditing) {

        try {
          this.error = '';
          this.pending = true;

          await new Promise((resolve) => {
            setTimeout(resolve, FAKE_LATENCY)
          })

          // Convert to UTC
          const operationMinStartUTC = this.convertLocalTimeToAbsoluteMinutes(this.operationLocalStart);
          const operationMinEndUTC = this.convertLocalTimeToAbsoluteMinutes(this.operationLocalEnd);

          console.log('sending', 'this.publicEmailAddress', this.publicEmailAddress, 'this.publicPhoneNumber', this.publicPhoneNumber);

          const account = await coreApi.updateAccountSettings({
            officeName: this.accountName,
            publicEmailAddress: this.publicEmailAddress ? this.publicEmailAddress : undefined,
            publicPhoneNumber: this.publicPhoneNumber ? this.publicPhoneNumber.replace(/[^0-9]/g, '') : undefined,
            operationMinStartUTC: operationMinStartUTC,
            operationMinEndUTC: operationMinEndUTC
          })
          store.accountInfo = account;
        } catch (err) {
          this.error = err.message;
          return;
        } finally {
          this.pending = false;
        }
      }

      this.isEditing = !this.isEditing;
    },
    getFormattedPhone() {
      let v = store.accountInfo.publicPhoneNumber;
      v = v.replace(/\D/g, '').slice(0, 10);
      if (v.length >= 7) {
        return `(${v.substring(0, 3)}) ${v.substring(3, 6)}-${v.substring(6, 10)}`;
      } else if (v.length >= 4) {
        return `(${v.substring(0, 3)}) ${v.substring(3, 6)}`
      } else if (v.length >= 1) {
        return `(${v.substring(0, 3)}`
      }
    },
    convertLocalTimeToAbsoluteMinutes(localTimeString) {
      const [hours, minutes] = localTimeString.split(':').map(Number);
      const localDate = new Date();
      
      // Set local hours and minutes
      localDate.setHours(hours, minutes, 0);

      // Convert the local time to UTC absolute minutes
      const utcHours = localDate.getUTCHours();
      const utcMinutes = localDate.getUTCMinutes();
      
      const absoluteMinutesUTC = utcHours * 60 + utcMinutes;
      return absoluteMinutesUTC;
    },
    convertAbsoluteMinutesToLocalTime(absoluteMinutesUTC) {
      const utcDate = new Date();
      utcDate.setUTCHours(0, absoluteMinutesUTC); // Set UTC hours and minutes

      const localHours = utcDate.getHours();
      const localMinutes = utcDate.getMinutes();

      const localTimeString = `${localHours.toString().padStart(2, '0')}:${localMinutes.toString().padStart(2, '0')}`;
      return localTimeString;
    }
  }
}
</script>

<style scoped>
.panel-v2-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.setting-line-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.setting-line-item>* {
  flex: 1;
}

label {
  color: var(--color-txt-light);
}

.loading {
  height: 19px;
}

input,
select {
  border: none;
  padding: 10px;
  border: 1px solid var(--color-border-med);
  font-size: 16px;
}

.edit-value {
  display: flex;
  gap: 20px;
}
</style>