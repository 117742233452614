<template>
  <div class="panel">
    <div class="panel-header">
      <h2>Patient Charts</h2>
      <div class="search-bar-group">
        <div id="search-bar">
          <input type="text" class="form-control" placeholder="Search..." v-model="query" @input="handleQueryInput">
          <img src="/icons/search.png" alt="Search Icon">
        </div>
        <button class="button small primary" @click="$emit('openModal', 'create-patient')">Add Patient</button>
      </div>
    </div>
    <PatientListTable :patients="patients" :doneLoadingPatients="doneLoadingPatients" :loading="loading"
      @onEditPatient="editPatient" @onEditPicture="editPicture" @onArchivePatient="archivePatient"
      @onScrollBottom="loadNextPatients" @onEditContactMethods="editContactMethods" />
  </div>
</template>

<script>
import { searchPatients } from '@/api';
import PatientListTable from './PatientListTable.vue'

const QUERY_DELAY = 500;
const FAKE_LATENCY = 500;

export default {
  name: "PatientListPanel",
  data() {
    return {
      loading: false,
      patients: [],
      timeout: null,
      query: "",
      firstLoad: false,
      doneLoadingPatients: false
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query.search) {
      this.query = decodeURIComponent(query.search);
    }

    this.loadLatestPatients();
  },
  methods: {
    async loadLatestPatients() {
      try {
        this.loading = true;
        const { patients, isLast } = await searchPatients(this.query);
        this.patients = patients;
        this.doneLoadingPatients = isLast;
      } catch (err) {
        console.error('Failed to load latest patients');
      } finally {
        this.loading = false;
      }
    },
    async loadNextPatients() {
      if (this.loading) {
        return; // Already loading
      }

      if (this.doneLoadingPatients) {
        return; // Already loaded everything
      }

      try {
        this.loading = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of chats
        const oldestLoadedPatient = this.patients[this.patients.length - 1];
        const { patients, isLast } = await searchPatients(this.query, oldestLoadedPatient.id);

        // Push new chats to list
        this.doneLoadingPatients = isLast;
        for (let patient of patients) {
          this.patients.push(patient);
        }

      } catch (err) {
        console.error('Failed to load latest patients');
      } finally {
        this.loading = false;
      }
    },
    handleQueryInput() {
      // If theres a timeout, clear it
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.loadLatestPatients();
      }, QUERY_DELAY);
    },
    handlePatientUpdated(patient) {
      this.patients = this.patients.map(pat => {
        if (pat.id != patient.id) return pat;
        return patient;
      })

      this.patients.sort((a, b) => {
        return new Date(b.dateLastUpdated) - new Date(a.dateLastUpdated)
      })
    },
    handlePatientCreated(patient) {
      this.patients.unshift(patient);
    },
    handlePatientArchived(patientId) {
      this.patients = this.patients.filter(pat => pat.id != patientId);
    },
    handlePatientPictureUpdated(patientId, pictureKey) {
      const patient = this.patients.find(pat => pat.id == patientId);
      if (!patient) return;
      patient.profilePictureKey = pictureKey;
    },
    editPatient(patient) {
      this.$emit('openModal', 'edit-patient', { patient: patient })
    },
    editPicture(patient) {
      this.$emit('openModal', 'patient-picture', { patient: patient })
    },
    editContactMethods(patient) {
      this.$emit('openModal', 'edit-contact-methods', { patient: patient });
    },
    archivePatient(patient) {
      this.$emit('openModal', 'archive-patient', { patient: patient })
    },

  },
  emits: ["openModal"],
  components: { PatientListTable }
};
</script>

<style scoped>
.panel {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.table {
  height: 1px;
  flex: 1;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
}

.table-header {
  padding: 15px 25px;
  padding-left: 100px;
  padding-right: 75px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 25px;
  position: relative;
  z-index: 100;
}

.column-header {
  flex: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

.search-bar-group {
  display: flex;
  gap: 10px;
  max-width: 100%;
}

.search-bar-group button {
  flex-shrink: 0;
}

#search-bar {
  position: relative;
  width: 500px;
}

#search-bar .form-control {
  padding-left: 44px;
}

#search-bar img {
  position: absolute;
  left: 10px;
  top: calc(50% - 10px);
  height: 20px;
  width: 20px;
  display: block;
  opacity: 0.75;
}

.table {
  overflow-y: overlay;
}

.loading-done {
  color: #CCC;
}

@media screen and (max-width: 1000px) {

  .panel-header {
    flex-wrap: wrap;
  }
}
</style>