import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import InputNode from './InputNode.vue'; // Import your component

export default Node.create({
  name: 'input-node',
  group: 'block',
  selectable: true,
  draggable: true,

  addAttributes() {
    return {
      label: {
        default: 'Default Label',
      },
      requirement: {
        default: 'Required'
      },
      type: {
        default: 'Text'
      },
      options: {
        default: []
      }
    };
  },

  parseHTML() {
    return [{
      tag: 'div.input-node'
    }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes ];
  },

  addNodeView() {
    return VueNodeViewRenderer(InputNode);
  },
});