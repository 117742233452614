<template>
  <div class="send-button-container">
    <button class="button primary small send-button" @click.prevent="togglePopup" :data-hidden="page.length > 0">
      <img v-if="isSendingMessage" src="/loading-white.svg" class="loading" alt="Loading Icon">
      <img v-else src="@/assets/icons/right-arrow.png" class="right-arrow" alt="Right Arrow">
    </button>
    <transition name="modal-pop" mode="out-in">
      <div class="send-popup" ref="popup" v-if="page">
        <transition name="modal-slide" mode="out-in">
          <!-- No contact methods -->
          <div class="popup-page" v-if="page == 'no-contact-methods'">
            <button class="button primary popup-button" disabled>
              No Contact Methods
            </button>
          </div>
          <!-- First Page: Select Mode -->
          <div class="popup-page" v-else-if="page == 'select-security-level'">
            <button class="button primary popup-button" @click="openPage('select-secure-method')">
              <img src="@/assets/icons/secure.png" alt="Secure Icon">
              Send Secure Message
              <img src="@/assets/icons/right-arrow.png" alt="Right Arrow">
            </button>
            <button class="button primary popup-button" @click="openPage('select-insecure-method')">
              Send Regular Message
              <img src="@/assets/icons/right-arrow.png" alt="Right Arrow">
            </button>
          </div>
          <!-- Contact Method Page: Secure -->
          <div class="popup-page" v-else-if="page == 'select-secure-method'">
            <button class="button primary popup-button" v-for="method of patient.contactMethods" :key="method"
              @click.prevent="sendMessage(method, true)" :disabled="!isMethodEnabled(method, true)" v-tooltip="getDisabledMessage(method, true)">
              <img v-if="method.type == 'EMAIL'" src="@/assets/icons/email.png" alt="Email Icon">
              <img v-if="method.type == 'PHONE'" src="@/assets/icons/sms.png" alt="SMS Icon">
              <span>Send secure to: <u>{{ getTextForContactMethod(method) }}</u></span>
            </button>
          </div>
          <!-- Contact Method Page: Insecure -->
          <div class="popup-page" v-else-if="page == 'select-insecure-method'">
            <button class="button primary popup-button" v-for="method of patient.contactMethods" :key="method"
              @click.prevent="sendMessage(method, false)" :disabled="!isMethodEnabled(method, false)" v-tooltip="getDisabledMessage(method, false)">
              <img v-if="method.type == 'EMAIL'" src="@/assets/icons/email.png" alt="Email Icon">
              <img v-if="method.type == 'PHONE'" src="@/assets/icons/sms.png" alt="SMS Icon">
              <span>Send to: <u>{{ getTextForContactMethod(method) }}</u></span>
            </button>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { store } from '../../../store'

const ALLOWED_INSECURE_MIMETYPES = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png']

export default {
  name: 'PatientChatSendButton',
  props: {
    patient: Object,
    files: Array,
    text: Array,
    isSendingMessage: Boolean
  },
  data() {
    return {
      page: '',
      store
    }
  },
  methods: {
    togglePopup() {
      if (this.page) {
        this.page = '';
      } else {
        if (this.patient.contactMethods.length == 0) {
          this.page = 'no-contact-methods';
        } else if (!this.isInsecureEnabledForAccount()) {
          this.page = 'select-secure-method'
        } else {
          this.page = 'select-security-level'
        }
      }
    },
    openPage(page) {
      this.page = page;
    },
    sendMessage(method, isSecure) {
      if (!this.isMethodEnabled(method, isSecure)) return;
      this.$emit('onSendMessage', method, isSecure);
      this.page = '';
    },
    isMethodEnabled(method, isSecure) {
      const accountInfo = this.store.accountInfo;

      if (method.type == 'EMAIL' && !accountInfo.publicEmailAddress) {
        return false; // canot send emails when no sender email available
      }

      if (method.type == 'EMAIL' && !isSecure) {
        return false; // cannot send insecure emails
      }

      if (method.type == 'PHONE' && !accountInfo.publicPhoneNumber) {
        return false; // cannot send sms when no sender number available
      }

      if (method.type == 'PHONE' && !isSecure) {
        // cannot send certain files over insecure
        for (let file of this.files) {
          if (!ALLOWED_INSECURE_MIMETYPES.includes(file.type)) {
            return false;
          }
        }
      }

      return true;
    },
    getDisabledMessage(method, isSecure) {
      const accountInfo = this.store.accountInfo;

      if (method.type == 'EMAIL' && !accountInfo.publicEmailAddress) {
        return 'Your account does not have an email connected.'
      }

      if (method.type == 'EMAIL' && !isSecure) {
        return 'The system does not support sending emails insecurely.'
      }

      if (method.type == 'PHONE' && !accountInfo.publicPhoneNumber) {
        return 'Your account does not have a phone number connected.'
      }

      if (method.type == 'PHONE' && !isSecure) {
        // cannot send certain files over insecure
        for (let file of this.files) {
          if (!ALLOWED_INSECURE_MIMETYPES.includes(file.type)) {
            return 'Only .png and .jpeg files can be sent over insecure SMS';
          }
        }
      }

      return null;
    },
    isInsecureEnabledForAccount() {
      const accountMessagingInfo = this.store.accountMessagingInfo;
      if (!accountMessagingInfo) {
        return false;
      }

      if (!accountMessagingInfo.allowInsecureMessages) {
        return false;
      }
      
      return true;
    },
    getTextForContactMethod(contactMethod) {

      let methodName;

      switch (contactMethod.type) {
        case 'EMAIL':
          if (contactMethod.description) {
            methodName = contactMethod.description;
          } else {
            methodName = contactMethod.emailAddress;
          }
          break;
        case 'PHONE':
          if (contactMethod.description) {
            methodName = contactMethod.description;
          } else {
            methodName = contactMethod.phoneNumber;
          }
          break;
      }

      return methodName;
    },
  },
  emits: ['onSendMessage']
}
</script>

<style scoped>
.send-button-container {
  position: relative;
}

.send-button {
  height: 44px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: opacity ease-in-out 0.2s;
}

.send-button[data-hidden="true"] {
  opacity: 0.5;
}

.send-popup {
  position: absolute;
  right: 0;
  bottom: calc(100% + 10px);
  padding-bottom: 10px;
  background: #FFFFFFAA;
  box-shadow: 0px 0px 1000px 1000px #FFFFFFAA;
}

.popup-page {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.popup-button {
  text-wrap: nowrap;
  padding: 15px;
  width: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 16px;
}

.popup-button img {
  height: 18px;
  display: block;
}

.popup-button:not(:disabled) img {
  filter: invert(1);
}

.send-button .right-arrow {
  height: 18px;
  display: block;
  filter: invert(1);
}
</style>