<template>
  <div id="full">
    <NavigationBar :links="[
      { path: '/meetings', label: 'Patients', hasNotif: false },
    ]" />
    <OfficeMeetingsPanel @openModal="openModal" />
    <OfficeMeetingsListPanel ref="listPanel" @openModal="openModal" />
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <SelectPatientModal v-if="modal.id == 'select-patient'" :mode="modal.data.mode" @openModal="openModal"
              @onMeetingCreated="handleMeetingCreated" />
            <SelectUsersModal v-else-if="modal.id == 'select-users'" @openModal="openModal"
              :patient="modal.data.patient" />
            <CreateMeetingModal v-else-if="modal.id == 'create-meeting'" @openModal="openModal" @closeModal="closeModal"
              :users="modal.data.users" :patient="modal.data.patient" @onMeetingCreated="handleMeetingCreated" />
            <SendPatientMeetingReminderModal v-else-if="modal.id == 'send-patient-reminder'" @openModal="openModal"
              @closeModal="closeModal" @onNotificationSent="handleNotificationSent" :patient="modal.data.patient"
              :meeting="modal.data.meeting" :type="modal.data.type" />
            <ShowInstantMeetingModal v-else-if="modal.id == 'show-instant-meeting'" @openModal="openModal"
              @closeModal="closeModal" :meeting="modal.data.meeting" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { userCheckAuth } from '@/api';
import NavigationBar from '@/components/shared/NavigationBar.vue'
import OfficeMeetingsListPanel from '@/components/meetings/OfficeMeetingsListPanel.vue'
import OfficeMeetingsPanel from '@/components/meetings/OfficeMeetingsPanel.vue'

import SelectPatientModal from '@/modals/SelectPatientModal.vue';
import SelectUsersModal from '@/modals/meetings/SelectUsersModal.vue'
import CreateMeetingModal from '@/modals/meetings/CreateMeetingModal.vue'
import SendPatientMeetingReminderModal from '@/modals/meetings/SendPatientMeetingReminderModal.vue';
import { ReminderType } from '../../utils/Enums';
import ShowInstantMeetingModal from '@/modals/meetings/ShowInstantMeetingModal.vue';

export default {
  name: 'OfficeMeetingsView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
    }
  },
  created() {
    userCheckAuth()
      .then(() => {
        console.info('Login checker - OK');
      })
      .catch(err => {
        if (err.message == 'Permission denied') {
          const currentUrl = window.location.href;
          this.$router.push('/login?ref=' + currentUrl);
        }
      });
  },
  methods: {
    openModal(id, data) {
      console.info('opening modal', id);
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleMeetingCreated(meeting) {
      this.openModal('send-patient-reminder', { patient: meeting.patient, meeting: meeting, type: ReminderType.INITIAL });
      const panelRef = this.$refs.listPanel;
      if (!panelRef) return;
      panelRef.handleMeetingCreated(meeting);
    },
    handleNotificationSent(meeting, notif, type = null) {
      if (type != null && type === ReminderType.INSTANT) {
        this.openModal('show-instant-meeting', { meeting: meeting }); // After showing them the reminder for instant meeting, lets now show them the meeting link
      } else {
        this.closeModal();
      }
      const panelRef = this.$refs.listPanel;
      if (!panelRef) return;
      panelRef.handleNotificationSent(meeting, notif);
    }
  },
  components: { NavigationBar, OfficeMeetingsPanel, OfficeMeetingsListPanel, SelectPatientModal, SelectUsersModal, CreateMeetingModal, SendPatientMeetingReminderModal, ShowInstantMeetingModal }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media screen and (max-width: 1000px) {
  #full {
    flex-direction: column;
  }
}
</style>