<template>
  <div id="full">
    <!-- Navigation bar -->
    <NavigationBar :links="[
      { path: '/account-forms', label: 'Account Forms' },
    ]" />

    <!-- Forms List Sidebar -->
    <FormsListSidebar @openModal="openModal" />

    <!-- Form Editor Panel -->
    <transition name="modal-appear" mode="out-in">
      <FormEditorPanel v-if="formInfo" :formInfo="formInfo" />
    </transition>

    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <CreateFormModal v-if="modal.id == 'create-form'" @closeModal="closeModal" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as formsApi from '../../api/forms'
import NavigationBar from '../../components/shared/NavigationBar.vue'
import FormsListSidebar from '../../components/forms/FormsListSidebar.vue'
import FormEditorPanel from '../../components/forms/FormEditorPanel.vue';
import CreateFormModal from '../../modals/forms/CreateFormModal.vue';

export default {
  name: 'AccountFormsView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      formInfo: null
    }
  },
  watch: {
    $route: {
      handler: function (newRoute) {
        this.formInfo = null;

        const formId = newRoute.params.id;
        if (formId) {
          this.attemptFetchFormInfo(formId);
        }
      },
      immediate: true
    }
  },
  methods: {
    async attemptFetchFormInfo(id) {
      try {
        this.formInfo = await formsApi.fetchFormInfo(id);
      } catch (err) {
        alert('Failed to load form: ' + err.message);
        console.error(err);
      }
    },
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
  },
  components: { NavigationBar, FormsListSidebar, FormEditorPanel, CreateFormModal }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  background: var(--color-fill-med);
}
</style>