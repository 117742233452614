<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Create Form</h1>
      <p class="subtitle">What should we call this patient form?</p>
    </div>
    <div class="modal-card-body">
      <form class="form" @submit.prevent="attemptCreateForm">
        <div class="form-group">
          <label for="" class="form-label">Form Name</label>
          <input type="text" class="form-control" v-model="name">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Form File</label>
          <input ref="file" type="file" class="form-control" accept="application/pdf">
        </div>
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptCreateForm">
        <img src="/loading-white.svg" alt="Loading" class="loading" v-if="isLoading">
        <span v-else>Create</span>
        <span v-if="currentStatusText">{{currentStatusText}}</span>
      </button>
      <p class="error">{{error}}</p>
    </div>
  </div>
</template>

<script>
import { store } from '../../store'
import * as formsApi from '../../api/forms.js'

export default {
  name: "CreateFormModal",
  data() {
    return {
      name: '',
      currentStatusText: '',
      isLoading: false,
      error: ''
    }
  },
  methods: {
    async attemptCreateForm() {
      if (this.isLoading) return;

      try {
        this.currentStatusText = ''
        this.isLoading = true;

        const form = await formsApi.createForm(this.name);

        const fileRef = this.$refs.file;
        if (fileRef != null && fileRef.files[0]) {
          this.currentStatusText = 'Uploading PDF...';
          const file = fileRef.files[0];
          const pageImages = await formsApi.importPdf(file);

          console.info('Attempting to parse PDF images', pageImages);
          await formsApi.parsePdf(form.id, pageImages,
            (message) => {
              this.currentStatusText = message;
            },
            (message) => {
              this.currentStatusText = message;
            });
        }

        store.accountForms.push(form);
        this.$emit('closeModal');

        setTimeout(() => {
          this.$router.push(`/account-forms/${form.id}`)
        }, 200)
      } catch (err) {
        this.error = err.message;
        console.error(err);
      } finally {
        this.currentStatusText = ''
        this.isLoading = false;
      }
    }
  },
  emits: ['openModal', 'closeModal']
};
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.user-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 25px;
}

.user-picture {
  width: 30px;
  height: 30px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.loading {
  margin: unset;
}
</style>