<template>
  <div class="modal-card">
      <div class="modal-card-header">
        <h1 class="title">Chat Does Not Exist</h1>
        <p class="subtitle">This usually means the chat was converted to a patient chat, or the patient was archived. If you believe this is incorrect, please contact DashQuill support.</p>
      </div>
      <div class="modal-card-footer">
        <button class="button danger" @click.prevent="$emit('closeModal')">
          Close
        </button>
      </div>
  </div>
</template>

<script>
export default {
  name: "DeletedChatModal",
  emits: ['closeModal']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}
</style>