<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Revoke Invite</h1>
      <p class="subtitle">Are you sure you want to revoke <u>{{invite.name}}</u>'s invitation? They will no longer be able to join your account.</p>
    </div>
    <div class="modal-card-footer">
      <button class="button danger" @click="attemptRevokeInvite">
        <img src="/loading-white.svg" alt="Loading" class="loading" v-if="pendingRevoke">
        <span v-else>Revoke Invite</span>
      </button>
      <p class="error" v-if="error">{{error}}</p>
    </div>
  </div>
</template>

<script>
import * as coreApi from '../../../api/core'

export default {
  name: "RevokeInviteModal",
  props: {
    invite: Object
  },
  data() {
    return {
      pendingRevoke: false,
      error: ''
    }
  },
  methods: {
    async attemptRevokeInvite() {
      if (this.pendingRevoke) return;

      try {
        this.pendingRevoke = true;
        const invitationId = this.invite.id;
        await coreApi.revokeInvite(invitationId);
        this.$emit('onInviteRevoked', invitationId)
      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.pendingRevoke = false;
      }
    },
  },
  emits: ['onInviteRevoked']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.modal-card-footer {
  margin-top: 0px;
}

</style>