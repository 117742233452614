<template>
  <div
    class="message-ref"
    :data-is-my-side="isMySide"
    @click.prevent="handleClick"
  >
    <span class="ref-header">
      <img
        src="@/assets/icons/forwarded.png"
        alt="Forwarded Icon"
        class="ref-icon"
      />
      <span>Forwarded Message</span>
    </span>
    <div class="ref-content">
      <div class="inner-ref-content">
        <img class="message-author" :src="getPicture()" alt="" />
        <img class="bubble-gray" src="@/assets/bubble-gray.png" alt="Bubble" />
        <div class="message-text">
          <i v-if="refContent.contentType == 'FILE'"
            >[Click to view attachment]</i
          >
          <span v-else>{{ refContent.textContent }}</span>
        </div>
      </div>

      <span class="ref-name">{{
        refContent.sender && refContent.sender.name
      }}</span>
    </div>
  </div>
</template>

<script>
import * as commonUtils from "../../../../utils/common";

export default {
  name: "MessageRef",
  props: {
    refChatType: String,
    refChatId: String,
    refContent: Object,
    isMySide: Boolean,
  },
  created() {
    console.log("refContent", this.refContent);
  },
  methods: {
    getPicture() {
      return commonUtils.getPatientPicture(this.refContent.sender);
    },
    handleClick() {
      const messageId = this.refContent.id;

      if (this.refChatType == "PatientChat") {
        this.$router.push(
          `/patient-chats/${this.refChatId}?highlight=${messageId}`
        );
      }
      if (this.refChatType == "UnverifiedChat") {
        this.$router.push(
          `/unverified-chats/${this.refChatId}?highlight=${messageId}`
        );
      }
    },
  },
};
</script>

<style scoped>
.message-ref {
  min-height: 48px;
  border-radius: 15px;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
}

.ref-header {
  padding: 12px;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  line-height: 1.5;
}

.ref-icon {
  height: 20px;
  display: block;
  filter: invert(1);
}

.ref-content {
  display: flex;
  position: relative;
  background: #fff;
  border-radius: 12px;
  flex-direction: column;
}

.inner-ref-content {
  display: flex;
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 9px;
  gap: 10px;
  position: relative;
  border-radius: 12px;
  flex-direction: row;
}

.ref-name {
  padding-left: 18px;
  font-size: 14px;
  color: #777;
}

.message-ref[data-is-my-side="true"] {
  background: #653ee5;
  color: #fff;
  flex-direction: row;
}

.message-ref[data-is-my-side="false"] {
  background: #eee;
  color: #000;
}

.message-ref[data-is-my-side="true"] .ref-content {
  border: 2px solid #653ee5;
}

.message-ref[data-is-my-side="false"] .ref-content {
  border: 2px solid #eee;
}

.message-ref[data-is-my-side="false"] .ref-icon {
  filter: invert(0);
}

.message-text {
  padding: 12px;
  min-height: 48px;
  border-radius: 15px;
  padding: 12px;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-line;
  background: #eee;
  color: #000;
}

.message-author {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: block;
}

.bubble-gray {
  position: absolute;
  left: 68px;
  bottom: 18px;
  width: 20px;
  z-index: 0;
}
</style>