<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Edit Chat Members</h1>
      <p class="subtitle">Select which members you want to be in this chat.</p>
    </div>
    <div class="modal-card-body">
      <div class="user-list-item" v-for="user of store.accountUsers" :key="user.id" @click="onToggleUser(user.id)">
        <CheckBox size="25px" thickness="3px" :isChecked="isUserSelected(user.id)" />
        <img :src="getProfilePicture(user)" alt="User Picture" class="user-picture">
        <span class="user-name">{{ user.name }}</span>
        <span class="user-role">{{ user.practiceRole }}</span>
        <span v-if="isAddingUser(user.id)" class="status-adding">ADDING</span>
        <span v-if="isRemovingUser(user.id)" class="status-removing">REMOVING</span>
      </div>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click="attemptUpdateUsers">
        <img v-if="isLoading" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Save Changes</span>
      </button>
      <p class="error" v-if="error">{{error}}</p>
    </div>
  </div>
</template>

<script>
import { store } from '../../../store'
import * as commonUtils from '../../../utils/common.js'
import * as coreApi from '../../../api/core.js'
import * as messagingApi from '../../../api/messaging.js'
import CheckBox from '../../../components/shared/CheckBox.vue';

export default {
  name: "GroupChatDetailsModal",
  props: {
    chatInfo: Object
  },
  data() {
    return {
      store,
      selected: new Set(),
      isLoading: false,
      error: ''
    }
  },
  mounted() {
    this.attemptFetchUsers();

    for (let user of this.chatInfo.users) {
      this.selected.add(user.id);
    }
  },
  methods: {
    async attemptFetchUsers() {
      try {
        const users = await coreApi.listAllUsers();
        store.accountUsers = users;
      } catch (err) {
        console.error('Error', err);
      }
    },
    async attemptUpdateUsers() {
      if (this.isLoading) return;

      try {
        this.isLoading = true;

        const users = Array.from(this.selected.values());
        const chat = await messagingApi.groupChatUpdateMembers(this.chatInfo.id, users);
        this.$emit('onUpdateMembers', chat);
      } catch (err) {
        console.error('Error', err);
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    onToggleUser(userId) {
      if (this.selected.has(userId)) {
        this.selected.delete(userId);
      } else {
        this.selected.add(userId);
      }
    },
    isUserSelected(userId) {
      return this.selected.has(userId)
    },
    getProfilePicture(user) {
      return commonUtils.getUserPicture(user);
    },
    isAddingUser(userId) {
      const isInChat = this.chatInfo.users.find(usr => usr.id == userId);
      return !isInChat && this.selected.has(userId);
    },
    isRemovingUser(userId) {
      const isInChat = this.chatInfo.users.find(usr => usr.id == userId);
      return isInChat && !this.selected.has(userId);
    }
  },
  components: { CheckBox },
  emits: ['openModal', 'onUpdateMembers'],
};
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 15px;
  cursor: pointer;
  border-radius: 10px;
}

.user-list-item:hover {
  background: var(--color-fill-med);
}

.user-picture {
  width: 50px;
  height: 50px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.user-name {
  font-weight: bold;
}

.user-role {
  color: var(--color-txt-med);
}

.user-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 25px;
}

.user-picture {
  width: 30px;
  height: 30px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.status-adding {
  color: green;
  font-size: 14px;
}

.status-removing {
  color: red;
  font-size: 14px;
}

</style>