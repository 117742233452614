<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title"> {{ titleText }}</h1>
      <p class="subtitle"> {{ descriptionText }}</p>
    </div>
    <div class="modal-card-body">
      <div class="row row-control">
        <input class="form-control small-input" type="text" :value="formatPhoneNumber(patient.mobilePhoneNumber)"
          disabled />
        <button class="button primary small" :disabled="!patient.mobilePhoneNumber"
          @click="sendReminderNotification(NotificationType.PHONE)">Send Text</button>
      </div>
      <div class="reminder-list" v-if="meeting.reminderSendDates && meeting.reminderSendDates.length > 0">
        <strong>Reminders already sent:</strong>
        <div class="reminder-list-items">
          <div class="reminder-list-item" v-for="(reminder, idx) of meeting.reminderSendDates" :key="idx">
            <img src="/icons/sms.png" alt="Email Icon" class="lock-icon" v-tooltip="'SMS'"
              v-if="reminder.type == 'SMS'">
            <img src="/icons/email.png" alt="Email Icon" class="lock-icon" v-tooltip="'Email'"
              v-if="reminder.type == 'Email'">
            <span>{{ reminder.date }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="row row-control">
        <input class="form-control small-input" type="text" :value="patient.emailAddress || 'No Email Address'"
          disabled />
        <button class="button primary small" :disabled="!patient.emailAddress"
          @click="sendReminderNotification(NotificationType.EMAIL)">Send Email</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { sendMeetingReminder, fetchPatientChatInfoForPatient, createPatientChat } from "@/api";
import { ReminderType } from "@/utils/Enums";

export default {
  name: "SendPatientMeetingReminderModal",
  props: {
    patient: Object,
    meeting: Object,
    type: String,
  },
  data() {
    return {
      NotificationType: {
        EMAIL: 'Email',
        PHONE: 'SMS'
      },
      titleText: '',
      descriptionText: '',
    };
  },
  created() {
    switch (this.type) {
      case ReminderType.INSTANT:
        this.titleText = 'Send Meeting Notification';
        this.descriptionText = `Click the button below to send ${this.patient.firstName} ${this.patient.lastName} an text notification about this meeting, including the link.`;
        break;
      case ReminderType.INITIAL:
        this.titleText = 'Send Initial Reminder';
        this.descriptionText = `Click the button below to send ${this.patient.firstName} ${this.patient.lastName} an initial reminder about this meeting, including the link.`;
        break;
      case ReminderType.RECURRING:
        this.titleText = 'Send Meeting Reminder';
        this.descriptionText = `Click the button below to send ${this.patient.firstName} ${this.patient.lastName} a reminder about this meeting, including the link.`;
        break;
      default:
        this.titleText = 'Send Meeting Reminder';
        this.descriptionText = `Click the button below to send ${this.$patient.firstName} ${this.patient.lastName} a reminder about this meeting, including the link.`;
        break;
    }

    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log('patient', this.patient, this.meetingId, timeZone);
  },
  methods: {
    async sendReminderNotification(type) {
      let existingChat = null;
      try {
        existingChat = await fetchPatientChatInfoForPatient(this.patient.id);
      } catch (err) {
        console.log("No existing Chat");
      }

      if (!existingChat) { // Attempt to create a chat
        existingChat = await createPatientChat(this.patient.id);
      }

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const notif = await sendMeetingReminder(type, this.meeting.id, timeZone)
      this.$emit('onNotificationSent', this.meeting, notif, this.type);
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) {
        return 'No Phone Number'
      }

      let v = phoneNumber;
      v = v.replace(/\D/g, '').slice(0, 10);
      if (v.length >= 7) {
        return `(${v.substring(0, 3)}) ${v.substring(3, 6)}-${v.substring(6, 10)}`;
      } else if (v.length >= 4) {
        return `(${v.substring(0, 3)}) ${v.substring(3, 6)}`
      } else if (v.length >= 1) {
        return `(${v.substring(0, 3)}`
      }
    },
  },
  components: {},
  emits: ['openModal', 'closeModal', 'onMeetingCreated', 'onNotificationSent']
};
</script>

<style scoped>
.row-control {
  display: flex;
  justify-content: space-between;
}

.form-control {
  flex: 1;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-card {
  max-width: 500px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.user-icons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.user-picture,
.patient-picture {
  width: 40px;
  height: 40px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.modal-card-body {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

textarea {
  resize: none;
  height: 100%;
}

.icons-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

@media screen and (max-width: 1000px) {
  .desc-row {
    flex-direction: column;
  }
}

.reminder-list strong {
  display: block;
  margin-bottom: 10px;
}

.reminder-list-items {
  display: flex;
  flex-direction: column;
}

.reminder-list-item {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  padding: 5px;
}

.reminder-list-item img {
  height: 16px;
  display: block;
}
</style>