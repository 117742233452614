export const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const now = new Date();
  const ms = now - date;

  const sec = Math.floor(ms / 1000);
  if (sec <= 60) {
    return "just now";
  }

  const min = Math.floor(sec / 60);
  if (min <= 1) {
    return `1 minute ago`;
  }
  if (min <= 60) {
    return `${min} minutes ago`;
  }

  const hr = Math.floor(min / 60);
  if (hr <= 1) {
    return "1 hour ago";
  }
  if (hr <= 24) {
    return `${hr} hours ago`;
  }

  const days = Math.floor(hr / 24);
  if (days <= 1) {
    return "1 day ago";
  }
  if (days <= 7) {
    return `${days} days ago`;
  }

  return date.toLocaleDateString();
}

export const formatPhone = (phoneStr) => {
  if (!phoneStr) return '';
  let v = phoneStr.replace(/\D/g, '');
  if (v.length == 11) {
    v = v.substring(1, 11);
  } else {
    v = v.substring(0, 10);
  }

  if (v.length >= 7) {
    return `(${v.substring(0, 3)}) ${v.substring(3, 6)}-${v.substring(6, 10)}`;
  } else if (v.length >= 4) {
    return `(${v.substring(0, 3)}) ${v.substring(3, 6)}`
  } else if (v.length >= 1) {
    return `(${v.substring(0, 3)}`
  }

  return '';
}

export const getPatientName = (patient) => {
  if (patient.middleName) {
    return `${patient.firstName} ${patient.middleName} ${patient.lastName}`
  }
  
  return `${patient.firstName} ${patient.lastName}`
}

export const getPatientPicture = (patient) => {
  if (!patient.profilePictureKey) {
    return '/icons/patient.png'
  }

  const publicImages = process.env.VUE_APP_PUBLIC_FILES;
  return `${publicImages}/${patient.profilePictureKey}`;
}

export const getUserPicture = (user) => {
  if (!user.profilePictureKey) {
    return '/icons/patient.png'
  }

  const publicImages = process.env.VUE_APP_PUBLIC_FILES;
  return `${publicImages}/${user.profilePictureKey}`;
}

export const getTimeOfDay = (dateStr) => {
  const date = new Date(dateStr);

  const time = date.toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true // Use true for AM/PM format, false for 24-hour format
  });

  return time;
}