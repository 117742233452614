<template>
  <div class="conversation-header">
    <router-link to="/patient-chats" class="back-link" v-if="showBackArrow">
      <img src="/icons/back.png" alt="Back Icon" class="back-icon">
    </router-link>
    <div class="patient-info">
      <div class="scroll-container">
        <img :src="getPatientPicture()" alt="Patient Picture" class="chat-icon">
        <span class="patient-name">{{ getPatientName() }}</span>
        <div class="patient-contact-method" v-for="(method, idx) of getContactMethods()" :key="idx">
          <span v-if="method.type == 'PHONE'" v-tooltip="method.description">
            {{ formatPhoneNumber(method.phoneNumber) }}
          </span>
          <span v-else-if="method.type == 'EMAIL'" v-tooltip="method.description">
            {{ method.emailAddress }}
          </span>
        </div>
        <div class="patient-contact-method" v-if="getContactMethods().length == 0">
          <span>No Contact Methods</span>
        </div>
      </div>
      <div class="fade-right"></div>
    </div>
    <div class="button-list">
      <button class="button small secondary resolve-button" v-if="!chatInfo.isResolved"
        @click="$emit('onMarkedResolved')">
        Resolve
        <div class="indicator-icon"></div>
      </button>
    </div>
  </div>
</template>

<script>
import * as commonUtils from '../../../utils/common'

export default {
  name: 'PatientChatConversationHeader',
  props: {
    chatInfo: Object,
    showBackArrow: Boolean
  },
  methods: {
    getPatientPicture() {
      return commonUtils.getPatientPicture(this.chatInfo.patient);
    },
    getPatientName() {
      return commonUtils.getPatientName(this.chatInfo.patient);
    },
    getContactMethods() {
      if (!this.chatInfo.patient) {
        return [];
      }

      if (!this.chatInfo.patient.contactMethods) {
        return [];
      }

      return this.chatInfo.patient.contactMethods;
    },
    formatPhoneNumber(phone) {
      return commonUtils.formatPhone(phone);
    }
  },
  emits: ['onMarkedResolved']
}
</script>

<style scoped>
.conversation-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.patient-info {
  position: relative;
  width: 1px;
  flex: 1;
  overflow-x: hidden;
}

.scroll-container {
  position: relative;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  text-wrap: nowrap;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.fade-right {
  pointer-events: none;
  /* display: none; */
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to right, transparent, #FFF);
}

.chat-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.patient-name {
  font-weight: bold;
}

.patient-contact-method {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  border-left: 1px solid var(--color-border-med);
}

.indicator-icon {
  margin-left: 10px;
  z-index: 100;
}

.back-link {
  display: none;
}

.back-icon {
  height: 20px;
  display: block;
}

@media screen and (max-width: 1000px) {
  .back-link {
    display: block;
  }
}
</style>