<template>
  <div class="panel-v2 form-editor-panel">
    <div class="panel-v2-header">
      <div class="form-info">
        <h2 class="heading">Form Name</h2>
      </div>
      <div class="toolbar" v-if="editor">
        <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
          H1
        </button>
        <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
          H2
        </button>
        <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
          H3
        </button>
        <button @click="editor.chain().focus().setParagraph().run()"
          :class="{ 'is-active': editor.isActive('paragraph') }">
          P
        </button>
        <button
          @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">TBL</button>
        <button @click="addImage">IMG</button>
        <button @click="addInput">BOX</button>
      </div>
      <div class="actions">
        <button class="button primary small" @click.prevent="attemptSaveForm">
          <img v-if="isSaving" src="/loading-white.svg" alt="Loading" class="loading">
          <span v-else>Save</span>
        </button>
      </div>
    </div>
    <div class="panel-v2-body">
      <div class="editor">
        <EditorContent :editor="editor" />
      </div>
    </div>
  </div>
</template>

<script>
import * as formsApi from '../../api/forms'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import ImageResize from 'tiptap-extension-resize-image';
import InputNode from './nodes/InputNode'
import Placeholder from '@tiptap/extension-placeholder'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Underline from '@tiptap/extension-underline'

export default {
  name: 'FormEditorPanel',
  props: {
    formInfo: Object
  },
  data() {
    return {
      editor: null,
      content: '',
      isSaving: false
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          },
        }),
        Underline,
        ImageResize,
        InputNode,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        Placeholder.configure({
          placeholder: 'Start typing here...'
        })
      ],
      content: ''
    })

    const layout = this.formInfo.layout;
    if (layout) {
      this.editor.commands.setContent(layout);
      console.info('Loaded form layout', layout);
    }


  },
  beforeUnmount() {
    setTimeout(() => {
      this.editor.destroy();
      console.info('Editor was destroyed', this.editor);
    }, 1000)
  },
  methods: {
    async attemptSaveForm() {
      try {
        this.isSaving = true;

        const layout = this.editor.getJSON();
        await formsApi.updateForm(this.formInfo.id, layout);

        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        })

      } catch (err) {
        console.error(err);
      } finally {
        this.isSaving = false;
      }
    },
    exportData() {
      console.info(this.editor.getJSON());

      console.info(this.editor.getHTML());
    },
    addImage() {
      const url = window.prompt('URL')

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },
    addInput() {
      this.editor.chain().focus().insertContent({
        type: 'input-node', // Node name from your custom node class
        attrs: { type: 'Text' },
      }).run();
    },
  },
  components: { EditorContent }
}
</script>

<style scoped>
.form-editor-panel {
  flex: 1;
  background: transparent;
}

.panel-v2-header {
  gap: 20px;
  background: var(--color-fill-light);
}

.panel-v2-body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  flex: 1;
}

.toolbar button {
  padding: 10px;
  background: var(--color-fill-light);
  border-radius: 5px;
  border: none;
  outline: none;
  border: 1px solid var(--color-border-med);
  cursor: pointer;
}

.toolbar button.is-active {
  background: var(--color-primary);
  color: #FFF;
}

.editor {
  border: 1px solid var(--color-border-med);
  background: #FFF;
  width: 100%;
  max-width: 1000px;
  font-size: 16px;
}
</style>

<style>
.tiptap {
  padding: 50px;
  outline: none;
}

.tiptap img.ProseMirror-selectednode {
  outline: 2px solid blue;
}

/* Placeholder (at the top) */
p.is-editor-empty:first-child::before {
  color: #999;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}

.tiptap td,
.tiptap th {
  border: 1px solid #000;
  text-align: left;
}

.tiptap h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.tiptap h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.tiptap h3 {
  font-size: 16px;
  margin-bottom: 20px;
}
</style>