<template>
  <div class="patient-list-table" @scroll="onScroll">
    <table cellspacing="0" cellpadding="0">
      <thead>
        <tr>
          <th width="25%" style="padding-left: 100px">Patient Name</th>
          <th width="20%">Date of Birth</th>
          <th width="20%">Contact Methods</th>
          <th>Last Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="patient of patients" :key="patient.id" @click="$emit('onEditPatient', patient)">
          <td class="column-patient-name">
            <img :src="getProfilePicture(patient)" alt="Patient Picture" class="patient-picture"
              @click.stop.prevent="$emit('onEditPicture', patient)">
            <span class="patient-name">{{ getPatientName(patient) }}</span>
          </td>
          <td class="column-patient-dob">
            <span class="patient-dob">{{ patient.dateOfBirth }}</span>
          </td>
          <td class="column-contact-methods">
            <div class="contact-methods" @click.stop.prevent="$emit('onEditContactMethods', patient)">
              <div class="method-icon" v-for="method of patient.contactMethods" :key="method"
                v-tooltip="getMethodTooltip(method)">
                <img src="@/assets/icons/sms.png" v-if="method.type == 'PHONE'" alt="SMS Icon">
                <img src="@/assets/icons/email.png" v-else-if="method.type == 'EMAIL'" alt="Email Icon">
              </div>
              <div class="method-icon add-method">
              <img src="@/assets/icons/add.png" alt="Add Icon">
            </div>
            </div>
          </td>
          <td class="column-last-updated">
            <span class="last-updated">{{ getFormattedDate(patient.dateLastUpdated) }}</span>
          </td>
          <td>
            <img src="/icons/trash.png" class="archive-patient-icon" alt="Archive Patient Buttton"
              @click.stop.prevent="$emit('onArchivePatient', patient)">
          </td>
        </tr>
      </tbody>
    </table>
    <div class="loading-status" v-if="!doneLoadingPatients">
      <img src="/loading.svg" class="loading-icon" v-if="loading">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatientListTable',
  props: {
    patients: Array,
    doneLoadingPatients: Boolean,
    loading: Boolean
  },
  methods: {
    getPatientName(patient) {
      const name = [
        patient.firstName,
        patient.middleName,
        patient.lastName
      ].filter(part => part).join(' ');
      return name;
    },
    getProfilePicture(patient) {
      if (!patient.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${patient.profilePictureKey}`;
    },
    formatPhoneNumber(phone) {
      return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
    },
    getFormattedDate(dateStr) {
      const date = new Date(dateStr);
      const now = new Date();
      const ms = now - date;

      const sec = Math.floor(ms / 1000);
      if (sec <= 60) {
        return "just now";
      }

      const min = Math.floor(sec / 60);
      if (min <= 1) {
        return `1 minute ago`;
      }
      if (min <= 60) {
        return `${min} minutes ago`;
      }

      const hr = Math.floor(min / 60);
      if (hr <= 1) {
        return "1 hour ago";
      }
      if (hr <= 24) {
        return `${hr} hours ago`;
      }

      const days = Math.floor(hr / 24);
      if (days <= 1) {
        return "1 day ago";
      }
      if (days <= 7) {
        return `${days} days ago`;
      }

      return date.toLocaleDateString();
    },
    onScroll(event) {
      const list = event.target;
      const maxScroll = list.scrollHeight - list.offsetHeight;
      const percentScrolled = list.scrollTop / maxScroll;

      // Are we at bottom? Try to fetch chats
      if (percentScrolled >= 1) {
        this.$emit('onScrollBottom');
      }
    },
    getMethodTooltip(method) {
      switch (method.type) {
        case 'EMAIL':
          return method.emailAddress;
        case 'PHONE':
          return this.formatPhoneNumber(method.phoneNumber);
        default:
          return 'Unknown'
      }
    }
  },
  emits: ['onEditPatient', 'onEditPicture', 'onArchivePatient', 'onScrollBottom', 'onEditContactMethods']
}
</script>

<style scoped>
.patient-list-table {
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-border-light);
  border-radius: 5px;
  overflow: auto;
}

table {
  min-width: 100%;
  table-layout: fixed;
}

thead {
  position: sticky;
  top: 0;
  z-index: 100;
  background: var(--color-fill-dark);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

th {
  height: 50px;
  padding: 0px 25px;
  text-align: left;
  font-weight: 400;
  border: 1px solid var(--color-border-med);
  text-wrap: nowrap;
}

tbody tr {
  cursor: pointer;
}

tbody tr:hover {
  background: var(--color-fill-med);
}

th:not(:first-child) {
  border-left: none;
}

th:not(:last-child) {
  border-right: none;
}

td {
  height: 70px;
  padding: 0px 25px;
}

.column-patient-name {
  display: flex;
  align-items: center;
  gap: 25px;
  font-weight: bold;
}

.patient-phone {
  text-wrap: nowrap;
}

.last-updated {
  text-wrap: nowrap;
}

.patient-picture {
  width: 50px;
  height: 50px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.archive-patient-icon {
  width: 20px;
  height: 20px;
  display: block;
  opacity: 0.2;
}

.archive-patient-icon:hover {
  opacity: 1;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-methods {
  display: flex;
  gap: 10px;
}

.method-icon {
  background: var(--color-fill-med);
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 20px;
  display: block;
  border: 1px solid var(--color-border-med);
  cursor: pointer;
}

.method-icon img {
  width: 100%;
  height: 100%;
  display: block;
}

.add-method {
  background: none;
  border: 2px dashed var(--color-border-med);
}

.loading-icon {
  display: block;
  height: 100%;
}
</style>