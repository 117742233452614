<template>
  <div class="chat-conversation-footer" ref="footer">
    <!-- Left actions -->
    <slot name="left"></slot>
    <div class="button small secondary upload-button" @click.prevent="selectFiles()">
      <img src="@/assets/icons/upload.png" alt="Upload Files">
    </div>
    <!-- Message Box -->
    <div class="message-box" :data-has-files="files.length > 0" @dragover.prevent="" @dragenter.prevent=""
      @drop.prevent="handleFileDrop">
      <!-- Uploaded files section -->
      <div class="uploaded-files" v-if="files.length > 0">
        <div class="uploaded-file" v-for="(file, idx) of files" :key="idx">
          {{ formatFileName(file) }}
          <img src="@/assets/icons/close.png" alt="Close Icon" @click="removeFile(idx)">
        </div>
      </div>
      <!-- Text box -->
      <textarea class="message-box-input form-control" ref="text" v-model="text" @input="resizeTextArea"
        placeholder="Type message..." :disabled="isSendingMessage"></textarea>
    </div>
    <!-- Right actions -->
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  name: 'ChatConversationFooter',
  props: {
    isSendingMessage: Boolean
  },
  data() {
    return {
      text: '',
      files: []
    }
  },
  mounted() {
    this.resizeTextArea();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeTextArea);
  },
  methods: {
    resizeTextArea() {
      const target = this.$refs.text;
      target.style.height = '0px';
      target.style.height = `${target.scrollHeight}px`
    },
    clearTextArea() {
      this.text = '';
      this.files = [];

      this.$nextTick(() => {
        this.resizeTextArea();
      })
    },
    getText() {
      return this.text;
    },
    setText(text) {
      this.files = [];
      this.text = text;
      this.$nextTick(() => {
        this.resizeTextArea();
      })
    },
    getFiles() {
      return this.files
    },
    formatFileName(file) {
      const name = file.name;

      if (name.length < 24) {
        return name;
      }

      return name.substring(0, 12) + '...' + name.substring(name.length - 12);
    },
    selectFiles() {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;

      input.onchange = () => {
        const files = input.files;
        this.files.push(...files);

        this.$nextTick(() => {
          this.resizeTextArea();
        })
      }

      input.click();
    },
    handleFileDrop(event) {
      const files = event.dataTransfer.files;
      this.files.push(...files);

      this.$nextTick(() => {
        this.resizeTextArea();
      })
    },
    removeFile(idx) {
      this.files.splice(idx, 1);

      this.$nextTick(() => {
        this.resizeTextArea();
      })
    }
  },
}
</script>

<style scoped>
.chat-conversation-footer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 15px;
}

/* Textbox */

.message-box {
  position: relative;
  flex: 1;
}

.message-box-input {
  resize: none;
  overflow: hidden;
  line-height: 1.5;
  font-size: 16px;
}

.message-box[data-has-files="true"] .message-box-input {
  padding-top: 65px;
}

.uploaded-files {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 45px;
  width: calc(100% - 20px);
  display: flex;
  align-items: flex-start;
  gap: 10px;
  overflow-x: auto;
  overflow-y: visible;
  padding-bottom: 5px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.uploaded-files::-webkit-scrollbar {
  display: none;
}

.uploaded-file {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 2px 4px #00000022;
  display: flex;
  align-items: center;
  gap: 10px;
}

.uploaded-file img {
  height: 14px;
  cursor: pointer;
}

.upload-button {
  height: 44px;
}

.upload-button img {
  height: 18px;
  display: block;
}

</style>