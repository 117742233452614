<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">{{ account.name }}</h1>
      <p>Sign into the customer account with restricted priveleges for technical support and setup.</p>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptAdminLogin">Employee Login</button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import { adminLogin } from '@/api';

export default {
  name: "AccountInfoModal",
  props: {
    account: Object
  },
  data() {
    return {
      loading: false,
      error: ''
    }
  },
  methods: {
    async attemptAdminLogin() {
      try {
        this.loading = true;
        await adminLogin(this.account.id);
        
        this.$emit('closeModal');
        setTimeout(() => {
          this.$router.push('/apps')
        }, 250);
        
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    }
  },
  emits: ['openModal', 'closeModal']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.form {
  flex-direction: column;
}
</style>