<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Remove Contact Method</h1>
      <p class="subtitle">Are you sure you want to remove this contact method for <u>{{ getPatientName() }}</u>?
        Messages from this contact method will no longer arrive to the patient's chat</p>
    </div>
    <div class="modal-card-footer">
      <button class="button danger" @click="attemptRemoveContactMethod">
        <img v-if="pendingRemove" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Remove Method</span>
      </button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as coreApi from '../../../api/core'

export default {
  name: "DeleteContactMethod",
  data() {
    return {
      pendingRemove: false,
      error: ''
    }
  },
  props: {
    patient: Object,
    method: Object
  },
  methods: {
    async attemptRemoveContactMethod() {
      if (this.pendingRemove) return;

      try {
        this.pendingRemove = true;
        const patientId = this.patient.id;
        const patient = await coreApi.removeContactMethod({ patientId, methodId: this.method.id });
        this.$emit('onMethodRemoved', patient);
      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.pendingRemove = false;
      }
    },
    getPatientName() {
      const pat = this.patient;
      const name = [
        pat.firstName,
        pat.middleName,
        pat.lastName
      ].filter(part => part).join(' ');
      return name;
    },
  },
  emits: ['onMethodRemoved']
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}

.modal-card-footer {
  margin-top: 0px;
}
</style>