<template>
  <div class="meeting-list-item-row">
    <div class="meeting-date-strip" v-if="isMeetingFirstOfDay">
      <span class="meeting-date">{{ getMeetingDate() }}</span>
    </div>
    <div class="meeting-list-item">
      <div class="column icon-column">
        <img class="patient-picture" :src="getPatientProfilePicture(meeting.patient)"
          :title="`${meeting.patient.firstName} ${meeting.patient.lastName}`"
          :alt="`${meeting.patient.firstName} ${meeting.patient.lastName}`"
          v-tooltip="`Patient: ${meeting.patient.firstName} ${meeting.patient.lastName}`">
        <div class="row row-icons">
          <div class="user-icons" v-if="meeting.expectedUsers.length > 0">
            <img class="user-picture" :src="getProfilePicture(user)" :title="user.name" :alt="user.name"
              v-for="user of meeting.expectedUsers" :key="user.id" v-tooltip="'Staff: ' + user.name">
          </div>
        </div>
      </div>
      <div class="column desc-column">
        <div class="row">
          <div class="column">
            <h3>Meeting with {{ meeting.patient.firstName }} {{ meeting.patient.lastName }}</h3>
            <p v-if="meeting.description"> {{ meeting.description }}</p>
            <p v-else><i>No description</i></p>
            <!-- <p v-else>Click join below to initiate the meeting</p> -->
          </div>
        </div>
        <div class="row row-icons">
          <button class="button small primary" @click.prevent="attemptJoinRoom" :disabled="!isToday()">Join</button>
          <button class="button small secondary" @click="openRemindModal">Send Reminder</button>
        </div>
      </div>
      <div class="column date-column">
        <span>{{ getFormattedDate() }}</span>
        <h2>{{ getFormattedTime() }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { meetJoinMeetingProtected } from '@/api'
import { ReminderType } from '../../utils/Enums';

export default {
  name: 'MeetingListItem',
  props: {
    meeting: Object,
    isMeetingFirstOfDay: Boolean
  },
  methods: {
    openRemindModal() {
      const patient = this.meeting.patient;
      this.$emit('openModal', 'send-patient-reminder', { patient, meeting: this.meeting, type: ReminderType.RECURRING });
    },
    async attemptJoinRoom() {
      try {
        const meetingLink = await meetJoinMeetingProtected(this.meeting.roomName);
        const link = document.createElement('a');
        link.href = meetingLink;
        link.target = '_blank';
        link.click();
      } catch (err) {
        console.error('Failed to join room', err);
      }
    },
    getProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    getPatientProfilePicture(patient) {
      if (!patient.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${patient.profilePictureKey}`;
    },
    getFormattedDate() {
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      const inputDate = new Date(this.meeting.meetingTime);
      const today = new Date();

      const isSameDay = (date1, date2) => date1.toDateString() === date2.toDateString();

      // Check if the input date is today
      if (isSameDay(inputDate, today)) {
        return "Today";
      }

      // Check if the input date is tomorrow
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      if (isSameDay(inputDate, tomorrow)) {
        return "Tomorrow";
      }

      // Check if the input date is within this week
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay());

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      if (inputDate >= startOfWeek && inputDate <= endOfWeek) {
        return daysOfWeek[inputDate.getDay()];
      }

      // If not within this week, return formatted as "Month Day"
      return `${monthsOfYear[inputDate.getMonth()]} ${inputDate.getDate()}`;
    },
    getFormattedTime() {
      const date = new Date(this.meeting.meetingTime);

      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';

      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;

      const formattedTime = `${hours}:${minutes} ${ampm}`;
      return formattedTime;
    },
    isToday() {
      const inputDate = new Date(this.meeting.meetingTime);
      const today = new Date();
      return inputDate.toDateString() === today.toDateString();
    },
    getMeetingDate() {
      const date = new Date(this.meeting.meetingTime);
      const today = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

      // Check if the date is today
      if (date.toLocaleDateString() === today.toLocaleDateString()) {
        return "Today";
      }

      // Check if the date is yesterday
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
        return "Yesterday";
      }

      // Check if the date is within the last week
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayOfWeek = daysOfWeek[date.getDay()];
      if (today - date < 7 * oneDay) {
        return dayOfWeek;
      }

      // If more than a week ago, return MM/DD/YYYY format
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
    },
  },
  emits: ['openModal'],
}
</script>

<style scoped>
.meeting-list-item {
  width: 100%;
  padding: 25px;
  box-shadow: 0px 0px 8px #00000022;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.row-icons {
  align-items: center;
  gap: 10px;
}

.user-icons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.patient-picture {
  width: 60px;
  height: 60px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.user-picture {
  width: 30px;
  height: 30px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.desc-column {
  flex: 1;
}

.icon-column {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
  border-right: 1px solid #EEE;
}

.date-column {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-left: 1px solid #EEE;
  padding-left: 25px;
}

.meeting-date-strip {
  padding-top: 25px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meeting-date {
  font-size: 14px;
  padding: 10px;
  background: #F5F5F5;
  color: #999;
}

p i {
  color: #999;
}

@media screen and (max-width: 1000px) {
  .meeting-list-item {
    flex-direction: column-reverse;
  }

  .date-column {
    padding-left: 0px;
    border-left: unset;
    border-bottom: 1px solid #CCC;
    padding-bottom: 15px;
  }
}
</style>