<template>
  <div id="full">
    <NavigationBar :links="[
      { path: '/admin', label: 'Admin Portal', hasNotif: false }
    ]" />
    <AccountListPanel ref="panel" @openModal="openModal" />
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <RemoveAccountModal v-if="modal.id == 'remove-account'" :account="modal.data.account" @onAccountRemoved="handleAccountRemoved" />
            <!-- <EditFormsModal v-else-if="modal.id == 'edit-forms'" :account="modal.data.account" @onFormsToggled="handleFormsToggled" />
            <EditMessagingModal v-else-if="modal.id == 'edit-messaging'" :account="modal.data.account" @onMessagingToggled="handleMessagingToggled" />
            <EditMeetingsModal v-else-if="modal.id == 'edit-meetings'" :account="modal.data.account" @onMeetingsToggled="handleMeetingsToggled" /> -->
            <AccountInfoModal v-else-if="modal.id == 'account-info'" :account="modal.data.account" @closeModal="closeModal" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AccountListPanel from '@/components/core/admin/AccountListPanel.vue';
// import EditMessagingModal from '@/modals/core/admin/EditMessagingModal.vue';
// import EditMeetingsModal from '@/modals/core/admin/EditMeetingsModal.vue';
import RemoveAccountModal from '@/modals/core/admin/RemoveAccountModal.vue';
import AccountInfoModal from '@/modals/core/admin/AccountInfoModal.vue';
// import EditFormsModal from '@/modals/core/admin/EditFormsModal.vue';
import NavigationBar from '@/components/shared/NavigationBar.vue';

export default {
  name: "AdminView",
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
    }
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleAccountRemoved(accountId) {
      this.closeModal();

      // Remove account from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleAccountRemoved(accountId);
    },
    handleFormsToggled(accountId, isEnabled) {
      this.closeModal();

      // Remove account from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleFormsToggled(accountId, isEnabled);
    },
    handleMessagingToggled(accountId, isEnabled) {
      this.closeModal();

      // Remove account from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleMessagingToggled(accountId, isEnabled);
    },
    handleMeetingsToggled(accountId, isEnabled) {
      this.closeModal();

      // Remove account from panel
      const panelRef = this.$refs.panel;
      if (!panelRef) return;
      panelRef.handleMeetingsToggled(accountId, isEnabled);
    }
  },
  components: { NavigationBar, AccountListPanel, RemoveAccountModal, AccountInfoModal }
}
</script>

<style scoped>
</style>