<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Unsend Secure Message</h1>
      <p class="subtitle">Are you sure you want to unsend this secure message? This action will remove the message from the patient portal for both the patient and the office.</p>
    </div>
    <div class="modal-card-footer">
      <button class="button danger" @click.prevent="attemptUnsendMessage">
        <img v-if="loading" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Unsend Message</span>
      </button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as messagingApi from '../../api/messaging'

export default {
  name: "UnsendSecureMessage",
  props: {
    message: Object
  },
  data() {
    return {
      error: "",
      pending: false,
    };
  },
  methods: {
    async attemptUnsendMessage() {
      try {
        this.pending = true;
        await messagingApi.patientChatUnsendMessage(this.message.id);
        this.$emit('onMessageUnsent', this.message.id);
      } catch (err) {
        console.error("Failed to unsend chat", err);
      } finally {
        this.pending = false;
      }
    },
  },
  emits: ["closeModal", "onMessageUnsent"],
};
</script>

<style scoped>
.modal-card {
  max-width: 500px;
}
</style>