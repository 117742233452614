<template>
  <div class="conversation-header">
    <router-link to="/unverified-chats" class="back-link">
      <img src="/icons/back.png" alt="Back Icon" class="back-icon">
    </router-link>
    <div class="patient-info">
      <div class="scroll-container">
        <img src="/icons/patient.png" alt="Patient Picture" class="chat-icon">
        <span class="patient-name">Unknown Contact</span>
        <div v-if="getContactPhoneNumber()" class="patient-contact-method">{{ getContactPhoneNumber() }}</div>
        <div v-if="getContactEmail()" class="patient-contact-method">{{ getContactEmail() }}</div>
      </div>
      <div class="fade-right"></div>
    </div>
    <div class="button-list">
      <button class="button small secondary resolve-button" v-if="!chatInfo.isResolved"
        @click="$emit('onMarkedResolved')">
        Resolve
        <div class="indicator-icon"></div>
      </button>
      <button class="button small secondary resolve-button" @click="$emit('onConvertChat')">
        Create Patient
      </button>
    </div>
  </div>
</template>

<script>
import * as commonUtils from '../../../utils/common'

export default {
  name: 'PatientChatConversationHeader',
  props: {
    chatInfo: Object
  },
  methods: {
    getContactPhoneNumber() {
      return commonUtils.formatPhone(this.chatInfo.mobilePhoneNumber);
    },
    getContactEmail() {
      return this.chatInfo.emailAddress;
    }
  },
  emits: ['onMarkedResolved', 'onConvertChat']
}
</script>

<style scoped>
.conversation-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.patient-info {
  position: relative;
  width: 1px;
  flex: 1;
  overflow-x: hidden;
}

.scroll-container {
  position: relative;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  text-wrap: nowrap;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.fade-right {
  pointer-events: none;
  /* display: none; */
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to right, transparent, #FFF);
}

.chat-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.patient-name {
  font-weight: bold;
}

.patient-contact-method {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  border-left: 1px solid var(--color-border-med);
}

.indicator-icon {
  margin-left: 10px;
  z-index: 100;
}

.back-link {
  display: none;
}

.back-icon {
  height: 20px;
  display: block;
}

@media screen and (max-width: 1000px) {
  .back-link {
    display: block;
  }
}

.button-list {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
</style>