<template>
  <NodeViewWrapper class="input-node" :data-selected="isSelected">
    <!-- Controls -->
    <div class="input-node-controls">
      <!-- Label -->
      <textarea class="input-node-label" rows="1" tabindex="-1" :value="node.attrs.label"
        @input="handleUpdateLabel"></textarea>
      <!-- Type -->
      <!-- <select :value="node.attrs.type" @change="handleUpdateType">
        <option value="Text">Text</option>
        <option value="Date">Date</option>
        <option value="Phone">Phone</option>
        <option value="Email">Email</option>
        <option value="Dropdown">Dropdown</option>
        <option value="Multiselect">Multiselect</option>
        <option value="Signature">Signature</option>
      </select> -->
      <!-- Requirement -->
      <!-- <select :value="node.attrs.requirement" @change="handleUpdateRequirement">
        <option value="Required">Required</option>
        <option value="Optional">Optional</option>
        <option value="Conditional">Conditional</option>
      </select> -->
    </div>
    <!-- Input node content -->
    <div class="input-node-content">
      <div class="signature-box" v-if="node.attrs.type == 'Signature'">
        <canvas></canvas>
      </div>
      <div class="dropdown-list" v-else-if="node.attrs.type == 'Dropdown'">
        <div class="dropdown-list-item" v-for="(option, idx) of node.attrs.options" :key="idx">
          <div class="checkbox"></div>
          <textarea class="option-box" ref="options" rows="1" type="text" :value="option" @input="handleOptionInput($event, idx)" @blur="handleBlurOption" />
        </div>
        <div class="dropdown-list-item" v-if="isSelected">
          <div class="checkbox"></div>
          <textarea class="option-box" placeholder="Add another option..." type="text" rows="1" @input="handleCreateOption" />
        </div>
      </div>
      <textarea class="input-box" v-else rows="1" tabindex="-1" :placeholder="getPlaceholder()" @input="handleValueInput"></textarea>
    </div>
  </NodeViewWrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';

export default {
  props: nodeViewProps,
  data() {
    return {
      isSelected: false
    }
  },
  mounted() {
    window.addEventListener('mousedown', this.handleMouseDown);
  },
  unmounted() {
    window.removeEventListener('mousedown', this.handleMouseDown);
  },
  methods: {
    getPlaceholder() {
      const type = this.node.attrs.type;
      switch (type) {
        case 'Text':
          return 'Patient free response';
        case 'Date':
          return 'MM/DD/YYYY';
        case 'Phone':
          return '(123) 123-1234';
        case 'Email':
          return 'example@gmail.com';
        default:
          return '';
      }
    },
    handleMouseDown(event) {
      // Handle selecting the input
      const node = this.$el;
      if (node.contains(event.target)) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    },
    handleUpdateLabel(event) {
      const target = event.target;
      target.style.height = '1px';
      target.style.height = `${target.scrollHeight}px`

      this.updateAttributes({
        label: target.value
      })
    },
    handleUpdateType(event) {
      const target = event.target;
      this.updateAttributes({
        type: target.value
      })
    },
    handleUpdateRequirement(event) {
      const target = event.target;
      this.updateAttributes({
        requirement: target.value
      })
    },
    handleCreateOption(event) {
      // Make sure it's a legit key
      const target = event.target;
      const value = target.value;
      target.value = '';

      // Create a new option
      const options = this.node.attrs.options;
      options.push(value);
      this.updateAttributes({ options: options })

      // Focus it
      this.$nextTick(() => {
        const optionRefs = this.$refs.options;
        const lastOptionRef = optionRefs[optionRefs.length - 1];
        console.log(lastOptionRef);
        lastOptionRef.focus();
      })
    },
    handleValueInput(event) {
      // Resize box
      const target = event.target;
      target.style.height = '1px';
      target.style.height = `${target.scrollHeight}px`
    },
    handleOptionInput(event, idx) {
      const target = event.target;
      const options = this.node.attrs.options;
      options[idx] = target.value;
      this.updateAttributes({
        options: options
      })

      // Resize box
      target.style.height = '1px';
      target.style.height = `${target.scrollHeight}px`
    },
    handleBlurOption() {
      let options = this.node.attrs.options;
      options = options.filter(opt => opt.length > 0);
      this.updateAttributes({ options: options })
    }
  },
  components: { NodeViewWrapper }
};
</script>

<style scoped>
.input-node {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-node[data-selected="true"] {
  outline: 1px dashed #6C6C6C;
}

.input-node-controls {
  display: flex;
  align-items: flex-end;
  gap: 2px;
}

.input-node-controls select,
.input-node-controls textarea {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  line-height: 24px;
}

.input-node-controls textarea {
  border-right: 1px solid #EEE;
  padding: 0;
  color: #273864;
  font-weight: bold;
}

.input-node-label {
  flex: 1;
  line-height: 24px;
  font-size: 16px;
  resize: none;
}

.input-node-content .input-box {
  min-width: 400px;
  display: block;
  resize: none;
  font-size: 16px;
  overflow: hidden;
  padding: 6px;
  border: 1px solid #000;
  border-radius: 5px;
  line-height: 24px;

  /* TEMP */
  outline: none;
  cursor: pointer;
  /* pointer-events: none; */
}

.input-node-content .option-box {
  display: block;
  resize: none;
  font-size: 16px;
  overflow: hidden;
  padding: 0px;
  border-bottom: 1px solid #dfe3e8;
  line-height: 24px;

  /* TEMP */
  outline: none;
  cursor: pointer;
  /* pointer-events: none; */
}

::placeholder {
  opacity: 0.5;
  color: #000;
}

.signature-box {
}

.signature-box canvas {
  display: block;
  width: 400px;
  height: 75px;
  border: 1px solid #000;
  border-radius: 5px;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}

.dropdown-list-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.dropdown-list-item textarea {
  font-size: 16px;
  border: none;
}

.checkbox {
  width: 24px;
  height: 24px;
  border: 1px solid #000;
  border-radius: 50%;
}
</style>