import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.VUE_APP_API;

let socket = null;
let connectedRooms = new Set();
let listeners = new Map();

export async function connectToRoom(roomType, roomId) {

  // Connect socket if it does not already exist
  if (!socket) {
    await new Promise((resolve, reject) => {

      socket = io(URL, { withCredentials: true });

      socket.on('connect', () => {
        
        // Attempt to re-join rooms that we were in
        for (let roomName of connectedRooms) {
          const [roomType, roomId] = roomName.split(':')
          socket.emit('join-room', { roomType, roomId });
        }

        console.info('[Socket] Connected to server');
        resolve();
      })

      socket.on('error', (err) => {
        console.error('[Socket] Failed to connect to socket', err);
        reject();
      })

      socket.on('disconnect', () => {
        console.info('[Socket] Disconnected from server')
      })

      socket.on('connected-to-room', ({ roomName }) => {
        connectedRooms.add(roomName)
        console.info('[Socket] Connected to room', roomName);
      })

      socket.onAny((event, message) => {
        console.log(event, message);
        if (!listeners.has(event)) {
          return;
        }

        // Invoke all callbacks
        const callbacks = listeners.get(event);
        callbacks.forEach(callback => {
          callback(message);
        })
      })
    })
  }

  socket.emit('join-room', { roomType, roomId });
}

export function addEventListener(event, callback) {
  if (!listeners.has(event)) {
    listeners.set(event, []);
  }

  listeners.get(event).push(callback);
}

export function removeEventListener(event, callback) {
  if (!listeners.has(event)) {
    return;
  }

  listeners.set(event, listeners.get(event).filter(cb => cb != callback));
}