<template>
  <div class="panel-v2">
    <div class="panel-v2-header">
      <h2 class="heading">User Profile</h2>
      <button class="button small primary" @click="handleButtonClick">
        <img v-if="pending" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else-if="!isEditing">Edit</span>
        <span v-else>Save</span>
      </button>
    </div>
    <div class="panel-v2-body">
      <div class="setting-line-item">
        <label class="setting-label">Profile Picture</label>
        <div class="view-value" v-if="!isEditing">
          <img :src="getUserPicture()" alt="User Picture" class="user-picture">
        </div>
        <div class="edit-value" v-else>
          <img :src="getUploadedPicture()" alt="User Picture" class="user-picture replace-user-picture"
            @click="replaceUserPicture">
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Name / Role</label>
        <div class="view-value" v-if="!isEditing">{{ user.name }} / {{ user.practiceRole }}</div>
        <div class="edit-value" v-else>
          <input type="text" autocomplete="off" data-lpignore="true" data-form-type="other" v-model="userName">
          <input type="text" autocomplete="off" data-lpignore="true" data-form-type="other" v-model="practiceRole">
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Email Address</label>
        <div class="view-value" v-if="!isEditing">{{ user.email }}</div>
        <div class="edit-value" v-else>
          <input type="text" autocomplete="off" data-lpignore="true" data-form-type="other" :value="user.email"
            disabled>
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Password</label>
        <div class="view-value" v-if="!isEditing">••••••••••</div>
        <div class="edit-value" v-else>
          <input type="password" autocomplete="off" data-lpignore="true" data-form-type="other" value="**********"
            disabled>
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Admin Status</label>
        <div class="view-value" v-if="!isEditing">{{ user.isAccountAdmin ? 'Admin' : 'User' }}</div>
        <div class="edit-value" v-else>
          <select v-model="this.isAccountAdmin">
            <option :value="true">Admin</option>
            <option :value="false">User</option>
          </select>
        </div>
      </div>

      <div class="setting-line-item">
        <label class="setting-label">Account Status</label>
        <div class="view-value" v-if="!isEditing">{{ user.isDeleted ? 'Disabled' : 'Active' }}</div>
        <div class="edit-value" v-else>
          <select v-model="this.isDeleted">
            <option :value="true">Disabled</option>
            <option :value="false">Active</option>
          </select>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import * as coreApi from '../../../api/core'
import * as commonUtils from '../../../utils/common'
import { store } from '../../../store'

const FAKE_LATENCY = 500;

export default {
  name: 'UserProfilePanel',
  props: {
    user: Object
  },
  data() {
    return {
      isEditing: false,
      pending: false,
      error: '',

      userName: this.user.name,
      practiceRole: this.user.practiceRole,
      imageUrl: '',
      imageBlob: null,
      isImageChanged: false,
      isDeleted: this.user.isDeleted,
      isAccountAdmin: this.user.isAccountAdmin
    }
  },
  methods: {
    async handleButtonClick() {
      if (this.pending) return;

      if (this.isEditing) {

        try {
          this.error = '';
          this.pending = true;

          await new Promise((resolve) => {
            setTimeout(resolve, FAKE_LATENCY)
          })

          const user = await coreApi.updateUserSettings({
            userId: this.user.id,
            name: this.userName,
            practiceRole: this.practiceRole,
            isAccountAdmin: this.isAccountAdmin,
            isDeleted: this.isDeleted
          })

          if (this.isImageChanged) {
            const key = await coreApi.uploadUserProfilePicture(this.user.id, this.imageBlob);
            user.profilePictureKey = key;
            this.isImageChanged = false;
          }

          store.accountUsers = store.accountUsers.map(usr => {
            if (usr.id != user.id) return usr;
            return user;
          })

        } catch (err) {
          console.error(err);
          this.error = err.message;
          alert('Error: ' + err.message);
          return;
        } finally {
          this.pending = false;
        }
      }

      this.isEditing = !this.isEditing;
    },
    getUserPicture() {
      return commonUtils.getUserPicture(this.user)
    },
    getUploadedPicture() {
      if (this.imageUrl) {
        return this.imageUrl;
      }

      return this.getUserPicture()
    },
    replaceUserPicture() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*'
      input.click();

      input.onchange = async (event) => {
        this.imageBlob = await this.resizeAndCropImage(event.target.files[0]);
        this.imageUrl = this.getBlobAsImageUrl(this.imageBlob);
        this.isImageChanged = true;
      }
    },
    getBlobAsImageUrl(blob) {
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(blob);
    },
    async resizeAndCropImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            try {
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              const size = 256;

              // Set canvas dimensions
              canvas.width = size;
              canvas.height = size;

              // Calculate cropping coordinates and dimensions
              const width = img.width;
              const height = img.height;
              let sourceX, sourceY, sourceWidth, sourceHeight;

              if (width > height) {
                sourceX = (width - height) / 2;
                sourceY = 0;
                sourceWidth = height;
                sourceHeight = height;
              } else {
                sourceX = 0;
                sourceY = (height - width) / 2;
                sourceWidth = width;
                sourceHeight = width;
              }

              // Draw the cropped image to the canvas
              context.fillStyle = '#FFF';
              context.fillRect(0, 0, size, size);
              context.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, size, size);

              // Convert canvas to Blob
              canvas.toBlob((blob) => {
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error('Canvas is empty'));
                }
              }, 'image/jpeg');
            } catch (error) {
              reject(error);
            }
          };
          img.onerror = reject;
          img.src = event.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
  },
  emits: ['onUserUpdated']
}
</script>

<style scoped>
.panel-v2-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.setting-line-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.setting-line-item>* {
  flex: 1;
}

label {
  color: var(--color-txt-light);
}

.loading {
  height: 19px;
}

input,
select {
  border: none;
  border: 1px solid var(--color-border-med);
  font-size: 16px;
  padding: 10px;
}

.user-picture {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  border: 1px solid var(--color-border-med);
  display: block;
}

.replace-user-picture {
  opacity: 0.9;
  cursor: pointer;
}

.replace-user-picture:hover {
  opacity: 1;
}

.edit-value {
  display: flex;
  gap: 20px;
}
</style>