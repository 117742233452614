<template>
  <div id="full">
    <div class="modal-card">
      <div class="modal-card-header">
        <h1>Import Forms</h1>
      </div>
      <div class="modal-card-body">
        <div class="import-job-list">
          <div class="import-job" v-for="job of importJobs" :key="job.id"
            @click="openModal('import-job', { importJob: job })">
            <img class="job-icon" src="@/assets/icons/form.png" alt="Import Job">
            <div class="job-description">
              <span class="job-name">{{ job.name }}</span>
              <p class="job-date">{{ formatDate(job.dateCreated) }}</p>
              <p class="job-status">
                <span>{{ job.status }}</span>
                <a v-if="job.status == 'SUCCEEDED'" target="_blank" :href="`/account-forms/${job.result}`">View Form</a>
                <span v-if="job.status == 'IN_PROGRESS'">{{ job.pageJsons.length }} / {{ job.pageImages.length }}</span>
              </p>
            </div>
            <img src="@/assets/icons/play.png" alt="Play Icon" class="play-icon"
              @click.prevent.stop="attemptStartImportJob(job.id)">
          </div>
        </div>
      </div>
      <div class="modal-card-footer">
        <button class="button primary" @click="selectFiles">
          <img v-if="isPending" src="/loading-white.svg" alt="Loading" class="loading">
          <span v-else>Upload Forms</span>
        </button>
      </div>
    </div>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <ImportJobModal v-if="modal.id == 'import-job'" :importJob="modal.data.importJob"
              @closeModal="closeModal" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as formsApi from '../../api/forms'
import * as commonUtils from '../../utils/common'
import ImportJobModal from '../../modals/forms/ImportJobModal.vue';

export default {
  name: 'ImportFormsView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      selectedFiles: [],
      importJobs: [],
      isLoading: false,
      isPending: false
    }
  },
  mounted() {
    this.attemptFetchImportJobs();
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    async attemptFetchImportJobs() {
      if (this.isLoading) return;

      try {
        this.isLoading = true;
        this.importJobs = await formsApi.fetchImportJobs();
        console.info('Loaded import jobs', this.importJobs)
      } catch (err) {
        alert(err.message);
      } finally {
        this.isLoading = false;
      }
    },
    async attemptStartImportJob(jobId) {
      try {
        const importJob = await formsApi.startImportJob(jobId);
        console.info('Started import job', jobId);

        this.importJobs = this.importJobs.map(job => {
          if (job.id == jobId) return importJob;
          return job;
        })
      } catch (err) {
        alert(err.message);
      }
    },
    async attemptCreateImportJobs(files) {
      if (this.isPending) return;

      try {
        this.isPending = true;
        const importJobs = await formsApi.createImportJobs(files);
        this.importJobs.unshift(...importJobs);
      } catch (err) {
        alert(err.message);
      } finally {
        this.isPending = false;
      }
    },
    selectFiles() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/pdf'
      input.multiple = true;

      input.onchange = () => {
        const files = input.files;
        this.attemptCreateImportJobs(files);
      }

      input.click();
    },
    formatDate(dateStr) {
      return commonUtils.formatDate(dateStr)
    }
  },
  components: { ImportJobModal }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--color-fill-med);
}

.modal-card-body {
  overflow-y: auto;
  height: 500px;
}

.import-job-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.import-job {
  border-radius: 10px;
  width: 100%;
  gap: 20px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-border-med);
  cursor: pointer;
  padding: 25px;
}

.job-icon {
  width: 50px;
  height: 50px;
}

.job-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.job-name {
  font-weight: bold;
}

.play-icon {
  width: 20px;
  height: 20px;
  display: block;
}

.job-status {
  display: flex;
  gap: 10px;
}

.job-date {
  color: #999;
}
</style>