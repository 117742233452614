<template>
  <div class="message-text" :data-is-my-side="isMySide">
    <template v-for="(part, idx) of getTextParts(textContent)">
      <template v-if="part.type == 'link'">
        <a :href="part.content" target="_blank" :key="idx" @click.stop="">{{ part.content }}</a>
      </template>
      <template v-else>
        <span :key="idx">{{ part.content }}</span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MessageText',
  props: {
    textContent: String,
    isMySide: Boolean
  },
  methods: {
    getTextParts(input) {
      if (!input) return [];

      const urlPattern = /https?:\/\/[^\s]+[^\s.,!?]/g;
      const result = [];
      let match;
      let lastIndex = 0;

      while ((match = urlPattern.exec(input)) !== null) {
        // Add text part before the link
        if (match.index > lastIndex) {
          result.push({
            type: "text",
            content: input.substring(lastIndex, match.index)
          });
        }

        // Extract the matched URL
        let url = match[0];

        // Check for trailing punctuation and remove it
        while (/[.,!?]$/.test(url)) {
          url = url.slice(0, -1);
        }

        // Add the link part
        result.push({
          type: "link",
          content: url
        });

        // Update lastIndex to the end of the current match
        lastIndex = match.index + match[0].length;
      }

      // Add any remaining text after the last link
      if (lastIndex < input.length) {
        result.push({
          type: "text",
          content: input.substring(lastIndex)
        });
      }

      return result;
    }
  }
}
</script>

<style scoped>
.message-text {
  padding: 12px;
  min-height: 48px;
  border-radius: 15px;
  padding: 12px;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-line;
}

.message-text[data-is-my-side="true"] {
  background: #653ee5;
  color: #FFF;
  flex-direction: row-reverse;
}

.message-text[data-is-my-side="false"] {
  background: #eee;
  color: #000;
}

.message-text[data-is-my-side="true"] a {
  color: #ebceff;
}

.message-text[data-is-my-side="false"] a {
  color: #0059ff;
}
</style>