<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Edit Patient Profile</h1>
    </div>
    <div class="modal-card-body">
      <form class="form">
        <div class="form-group">
          <label class="form-label" for="first-name">First Name <span class="required">(required)</span></label>
          <input type="text" id="first-name" class="form-control" autocomplete="off" data-lpignore="true"
            data-form-type="other" v-model="firstName">
        </div>
        <div class="form-group">
          <label class="form-label" for="middle-name">Middle Name</label>
          <input type="text" id="middle-name" class="form-control" autocomplete="off" data-lpignore="true"
            data-form-type="other" v-model="middleName">
        </div>
        <div class="form-group">
          <label class="form-label" for="last-name">Last Name <span class="required">(required)</span></label>
          <input type="text" id="last-name" class="form-control" autocomplete="off" data-lpignore="true"
            data-form-type="other" v-model="lastName">
        </div>
        <div class="form-group">
          <label class="form-label" for="date-of-birth">Date of Birth <span class="required">(required)</span></label>
          <input type="text" id="date-of-birth" class="form-control" autocomplete="off" data-lpignore="true"
            data-form-type="other" placeholder="MM/DD/YYYY" v-model="dateOfBirth" @input="formatDateOfBirth">
        </div>
        <!-- <div class="form-group">
          <label class="form-label" for="date-of-birth">Contact Methods</label>
          <div class="contact-method-list" @click="$emit('openModal', 'edit-contact-methods', { patient })">
            <div class="method-icon" v-for="method of patient.contactMethods" :key="method"
              v-tooltip="getMethodTooltip(method)">
              <img src="@/assets/icons/sms.png" v-if="method.type == 'PHONE'" alt="SMS Icon">
              <img src="@/assets/icons/email.png" v-else-if="method.type == 'EMAIL'" alt="Email Icon">
            </div>
            <div class="method-icon add-method">
              <img src="@/assets/icons/add.png" alt="Add Icon">
            </div>
          </div>
        </div> -->
        <input type="submit" value="" style="display: none">
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptUpdatePatient()">
        <img v-if="pendingUpdate" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Save Changes</span>
      </button>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as coreApi from '../../api/core.js'

export default {
  name: 'PatientCreateModal',
  props: {
    patient: Object,
  },
  data() {
    return {
      pendingUpdate: false,
      firstName: this.patient.firstName,
      middleName: this.patient.middleName,
      lastName: this.patient.lastName,
      dateOfBirth: this.patient.dateOfBirth,
      error: ''
    };
  },
  methods: {
    async attemptUpdatePatient() {

      if (this.pendingCreate) return;

      if (this.firstName.length < 1) {
        this.error = 'First name is a required field';
        return;
      }

      if (this.lastName.length < 1) {
        this.error = 'Last name is a required field';
        return;
      }

      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(this.dateOfBirth)) {
        this.error = 'Date of birth is not valid';
        return;
      }

      try {
        this.error = '';
        this.pendingUpdate = true;

        const payload = {
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          dateOfBirth: this.dateOfBirth
        }

        const patient = await coreApi.updatePatient(this.patient.id, payload);
        this.$emit('onUpdatePatient', patient)
      } catch (err) {
        this.error = err.message;
      } finally {
        this.pendingUpdate = false;
      }
    },
    formatDateOfBirth() {
      let v = this.dateOfBirth;
      v = v.replace(/\D/g, '').slice(0, 8);  // Remove non-digits and limit length
      if (v.length >= 5) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
      } else if (v.length >= 3) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2)}`;
      } else {
        this.dateOfBirth = v;
      }
    },
    getMethodTooltip(method) {
      switch (method.type) {
        case 'EMAIL':
          return method.emailAddress;
        case 'PHONE':
          return method.phoneNumber;
        default:
          return 'Unknown'
      }
    }
  },
  emits: ['onUpdatePatient', 'openModal']
}
</script>

<style scoped>
.modal-card {
  width: 500px;
}

.required {
  opacity: 0.5;
}

.method-icon {
  background: var(--color-fill-med);
  width: 50px;
  height: 50px;
  padding: 15px;
  border-radius: 25px;
  display: block;
  border: 1px solid var(--color-border-med);
  cursor: pointer;
}

.method-icon img {
  width: 100%;
  height: 100%;
  display: block;
}

.add-method {
  background: none;
  border: 2px dashed var(--color-border-med);
}

.contact-method-list {
  display: flex;
  gap: 10px;
  width: auto;
}
</style>