<template>
  <div class="panel-v2">
    <div class="panel-v2-header">
      <slot name="header"></slot>
    </div>
    <div class="panel-v2-body">
      <div class="message-list" ref="messageList" @scroll="handleScrollEvent">
        <div class="loading-status" v-if="!doneLoadingMessages">
          <img class="loading-icon" src="/loading.svg" v-if="isLoadingMessages">
        </div>
        <transition-group name="modal-fade" mode="out-in">
          <MessageRow v-for="(message, idx) of messages" :ref="`message-${message.id}`" :senderName="message.senderName"
            :senderPicture="message.senderPicture" :contactMethod="message.contactMethod" :key="message.id"
            :width="chatWidth" :dateCreated="message.dateCreated" :isMySide="message.isMySide"
            :showFooter="isMessageLastOfKind(idx)" :showPicture="isMessageLastOfKind(idx)"
            :showDateHeader="isMessageFirstOfDay(idx)" :showActions="showActions" :isSecure="message.isSecure"
            :isHighlighted="isMessageHighlighted(message)" @onClickAction="handleClickAction(message, $event)">
            <!-- Different message types -->
            <MessageFile v-if="message.contentType == 'FILE'" :fileContent="message.fileContent"
              :isMySide="message.isMySide" />
            <MessageRef v-else-if="message.contentType == 'REF'" :refContent="message.refContent"
              :refChatType="message.refChatType" :refChatId="message.refChatId" :isMySide="message.isMySide" />
            <MessageText v-else :textContent="message.textContent" :isMySide="message.isMySide" />
          </MessageRow>
        </transition-group>
      </div>
    </div>
    <div class="panel-v2-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>

import MessageRow from './messages/MessageRow.vue';
import MessageText from './messages/MessageText.vue';
import MessageFile from './messages/MessageFile.vue';
import MessageRef from './messages/MessageRef.vue';

export default {
  name: 'ChatConversationPanel',
  props: {
    messages: Array,
    isLoadingMessages: Boolean,
    doneLoadingMessages: Boolean,
    showActions: Boolean
  },
  data() {
    return {
      chatWidth: 0,
      savedScrollBottom: 0
    }
  },
  mounted() {
    window.addEventListener('resize', this.computeChatWidth);
    this.computeChatWidth();
  },
  unmounted() {
    window.removeEventListener('resize', this.computeChatWidth);
  },
  watch: {
    messages: function () {
      this.$nextTick(() => {
        // If messages were loaded, and there was a saved scroll position, restore to that spot
        if (this.savedScrollBottom <= 0) return;
        const messageList = this.$refs.messageList;
        if (!messageList) return;
        const scrollHeight = messageList.scrollHeight;
        const scrollBottom = scrollHeight - this.savedScrollBottom;
        messageList.scrollTop = scrollBottom;
        this.savedScrollBottom = 0;
      })
    }
  },
  methods: {
    computeChatWidth() {
      const messageListRef = this.$refs.messageList;
      if (!messageListRef) return;
      this.chatWidth = messageListRef.offsetWidth - 50;
    },
    scrollToBottom() {
      const messageList = this.$refs.messageList;
      if (!messageList) return;
      messageList.scrollTop = messageList.scrollHeight;
    },
    scrollToMessage(messageId) {
      // Construct the ref name dynamically
      const messageRef = this.$refs['message-' + messageId];

      // Check if the message exists and scroll to it
      if (messageRef && messageRef[0]) { // Vue $refs with v-for return an array
        console.log(messageRef);
        messageRef[0].$el.scrollIntoView({ block: 'start' });
      } else {
        console.warn('Message not found:', messageId);
      }
    },
    handleClickAction(message, action) {
      switch (action) {
        case 'forward-message':
          this.$emit('openModal', 'forward-message', { refMessageId: message.id })
          break;
        case 'unsend-message':
          this.$emit('openModal', 'unsend-message', { message: message })
          break;
      }
    },
    handleScrollEvent(event) {
      if (this.doneLoadingMessages) {
        return; // Previous fetch returned 0 messages
      }

      const messageList = event.target;
      const scrollHeight = messageList.scrollHeight;
      const scrollTop = messageList.scrollTop;
      const scrollBottom = scrollHeight - scrollTop;

      // Save how far we are from bottom
      this.savedScrollBottom = scrollBottom;

      // Are we at bottom? Try to fetch chats
      if (scrollTop <= 0) {
        this.$emit('onLoadMoreMessages');
      }
    },
    isMessageFirstOfDay(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];

      const datePrev = new Date(prev.dateCreated);
      const dateCurr = new Date(curr.dateCreated);
      return datePrev.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageLastOfDay(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];

      const dateCurr = new Date(curr.dateCreated);
      const dateNext = new Date(next.dateCreated);
      return dateNext.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageFirstOfKind(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      if (this.isMessageFirstOfDay(index)) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];
      return !this.areMessagesGrouped(prev, curr);
    },
    isMessageLastOfKind(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      if (this.isMessageLastOfDay(index)) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];
      return !this.areMessagesGrouped(curr, next);
    },
    isMessageHighlighted(message) {
      const query = this.$route.query;
      if (!query || !query.highlight) return false;
      return message.id == query.highlight;
    },
    areMessagesGrouped(message1, message2) {
      if (message1.senderId != message2.senderId) {
        return false;
      }

      if (message1.isSecure != message2.isSecure) {
        return false;
      }

      const cm1 = message1.contactMethod;
      const cm2 = message2.contactMethod;
      return cm1 == cm2;
    },
  },
  components: { MessageRow, MessageText, MessageFile, MessageRef },
  emits: ['onLoadMoreMessages', 'openModal']
}
</script>

<style scoped>
.panel-v2 {
  flex: 1;
}

.panel-v2-header:empty {
  display: none;
}

.panel-v2-footer:empty {
  display: none;
}

.panel-v2-body {
  padding: 0;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.loading-icon {
  display: block;
  height: 100%;
}

.message-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
  overflow-y: auto;
}

@media screen and (max-width: 1000px) {
  .panel-v2 {
    border-radius: 0px;
  }
}
</style>