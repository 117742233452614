<template>
  <div class="conversation-header">
    <router-link to="/group-chats" class="back-link">
      <img src="/icons/back.png" alt="Back Icon" class="back-icon">
    </router-link>
    <div class="chat-info">
      <div class="scroll-container">
        <div class="chat-icons" :style="{ width: `${getIconsWidth(chat)}px` }">
          <img class="chat-icon" :style="{ left: `${idx * 15}px` }" :src="icon.src" :alt="icon.alt"
            v-for="(icon, idx) of getIconsToShow(chat)" :key="idx">
        </div>
        <span class="chat-name">{{ chatInfo.name }}</span>
        <span class="chat-members" @click="$emit('openModal', 'chat-details')">{{ chatInfo.users.length }} Members</span>
      </div>
      <div class="fade-right"></div>
    </div>
  </div>
</template>

<script>

import * as commonUtils from '../../../utils/common'

const MAX_ICONS = 5;

export default {
  name: 'GroupChatConversationHeader',
  props: {
    chatInfo: Object
  },
  methods: {
    getIconsToShow() {
      return this.chatInfo.users
        .slice(0, MAX_ICONS)
        .map(user => {
          return {
            src: commonUtils.getUserPicture(user),
            alt: user.name
          }
        });
    },
    getIconsWidth() {
      return Math.min(this.chatInfo.users.length, MAX_ICONS) * 15 + 25;
    }
  },
  emits: ['openModal']
}
</script>

<style scoped>
.conversation-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.chat-info {
  position: relative;
  width: 1px;
  flex: 1;
  overflow-x: hidden;
}

.chat-name {
  font-weight: bold;
}

.scroll-container {
  position: relative;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  text-wrap: nowrap;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.fade-right {
  pointer-events: none;
  /* display: none; */
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to right, transparent, #FFF);
}

.chat-icons {
  position: relative;
  height: 40px;
}

.chat-icon {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  box-shadow: -1px 0px 1px #00000022;
}

.back-link {
  display: none;
}

.back-icon {
  height: 20px;
  display: block;
}

.chat-members:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 1000px) {
  .back-link {
    display: block;
  }
}
</style>