<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Forward Message</h1>
      <p class="subtitle">Select a group chat you would like to forward this message to</p>
    </div>
    <div class="modal-card-body">
      <div class="chat-list-items" @scroll="handleScrollEvent">
        <div class="chat-list-item" v-for="chat of getChatsToShow()" :key="chat.id" @click="selectChatId(chat.id)">
          <CheckBox size="25px" thickness="3px" :isChecked="selectedChatId == chat.id" />
          <div class="chat-icons" :style="{ width: `${getIconsWidth(chat)}px` }">
            <img class="chat-icon" :style="{ left: `${idx * 10}px` }" :src="icon.src" :alt="icon.alt"
              v-for="(icon, idx) of getIconsToShow(chat)" :key="idx">
          </div>
          <span class="chat-name">{{ chat.name }}</span>
          <div class="chat-size"> {{ chat.icons.length }} Members</div>
        </div>
        <div class="loading-status" v-if="!doneLoadingChats">
          <img class="loading-icon" src="/loading.svg" v-if="isLoadingChats">
        </div>
      </div>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptForwardMessage" :disabled="selectedChatId == null">
        <img v-if="isForwardingMessage" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Forward Message</span>
      </button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import CheckBox from '../../../components/shared/CheckBox.vue';
import * as messagingApi from '../../../api/messaging'
import * as commonUtils from '../../../utils/common'

const MAX_ICONS = 3;
const FAKE_LATENCY = 500;

export default {
  name: "ForwardMessageModal",
  props: {
    refMessageId: String,
    refChatType: String
  },
  data() {
    return {
      chats: [],
      isLoadingChats: false,
      doneLoadingChats: false,
      selectedChatId: null,
      isForwardingMessage: false,
      error: ''
    };
  },
  mounted() {
    this.loadLatestChats();
  },
  methods: {
    async loadLatestChats() {
      try {
        this.isLoadingChats = true;
        const { chats, isLast } = await messagingApi.listGroupChats();
        this.chats = chats;
        this.doneLoadingChats = isLast;
      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.isLoadingChats = false;
      }
    },
    async loadNextChats() {
      if (this.isLoadingChats) {
        return; // Already loaded
      }

      try {
        this.isLoadingChats = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of chats
        const oldestLoadedChat = this.chats[this.chats.length - 1];
        const { chats, isLast } = await messagingApi.listGroupChats(oldestLoadedChat.id);

        // Push new chats to list
        this.doneLoadingChats = isLast;
        this.chats.push(...chats);

      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.isLoadingChats = false;
      }
    },
    async attemptForwardMessage() {
      if (this.isForwardingMessage) {
        return;
      }

      if (!this.selectedChatId) {
        return;
      }

      try {
        this.error = '';
        this.isForwardingMessage = true;
        await messagingApi.groupChatForwardMessage(this.selectedChatId, this.refChatType, this.refMessageId)
        this.$router.push(`/group-chats/${this.selectedChatId}`);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isForwardingMessage = false;
      }
    },
    handleScrollEvent(event) {
      if (this.doneLoadingChats) {
        return; // Previous fetch returned 0 chats
      }

      const list = event.target;
      const maxScroll = list.scrollHeight - list.offsetHeight;
      const percentScrolled = list.scrollTop / maxScroll;

      // Are we at bottom? Try to fetch chats
      if (percentScrolled >= 1) {
        this.loadNextChats();
      }
    },
    getIconsToShow(chat) {
      const icons = chat.icons;
      return icons.slice(0, MAX_ICONS);
    },
    getIconsWidth(chat) {
      return Math.min(chat.icons.length, MAX_ICONS) * 10 + 20;
    },
    getChatsToShow() {
      const result = [];

      for (let chat of this.chats) {
        result.push({
          id: chat.id,
          link: `/group-chats/${chat.id}`,
          name: chat.name,
          time: commonUtils.formatDate(chat.dateLastUpdated),
          icons: chat.users.map(user => {
            return {
              src: commonUtils.getUserPicture(user),
              alt: user.name
            }
          }),
          isResolved: true
        })
      }

      return result;
    },
    selectChatId(id) {
      this.selectedChatId = id;
    }
  },
  components: { CheckBox },
  emits: ["openModal", "closeModal"],
};
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.chat-list-items {
  height: 200px;
  overflow-y: auto;
}

.chat-list-items a {
  text-decoration: none;
  color: unset;
}

.chat-list-item {
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  background: transparent;
  cursor: pointer;
  border-radius: 10px;
}

.chat-list-item:hover {
  background: var(--color-fill-med);
}

.chat-icons {
  position: relative;
  height: 30px;
}

.chat-icon {
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  box-shadow: -1px 0px 1px #00000022;
}

.chat-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.chat-name {
  font-weight: bold;
}

.chat-time {
  color: #666;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  display: block;
  height: 100%;
}
</style>